import * as React from "react"
import { EventDate } from "../backendServices/Types"
import { useLanguageState } from "../globalStates/LanguageState"
import { compareByStartTimeEventDate } from "../contentArea/program/Schedule"
import { eventTimezoneName, getTimeToLiveString, isEventDateLive } from "../utils/DateUtils"
// import CrsPersons from "./CrsPersons"
import styled from "styled-components"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { vgLive } from "./Icons"
import branding from "../branding/branding"
import moment from "moment"

/*********************************************************************************************
 * TILE SESSION DETAILS TIME
 **********************************************************************************************/

export const LobbySessionTimeDivLive = styled.div<{ addTopMargin: boolean }>`
    background-color: #fff;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    width: max-content;
    height: 20px;
    border: 1px solid white;
    padding: 15px;
    margin-bottom: 5px;
    align-items: center;
    color: #000;
    margin-top: ${(props) => (props.addTopMargin === true ? "10px" : "0px")};
`
const SessionTimeDivLive = styled.div`
    background-color: #fff;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    width: max-content;
    height: 20px;
    border: 1px solid white;
    padding: 15px;
    margin-bottom: 5px;
    align-items: center;
    color: #000;
`

export const DotLive = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
    animation: blinker 1.8s linear infinite;
    background-color: ${branding.dangerButtonColor};

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`

export const LiveTitle = styled.span`
    display: inline-block;
    margin-top: 9px;
`

const LobbyNextSessionDiv = styled.div`
    display: flex;
    align-items: center;
`
const NextSessionDiv = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    margin-top: 20px;
    white-space: nowrap;
`
const LobbyNextSessionTitle = styled.h6`
    margin-left: 10px;
    margin-bottom: 0px;
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 18px;
    margin-top: 2px;
`

const NextSessionTitle = styled.h6`
    margin-left: 10px;
    margin-bottom: 0px;
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
`

interface TileSessionDetailsTimeProps {
    isLive: boolean
    time?: string
    channelId?: string
    color?: string
    addTopMargin: boolean
    lobby?: boolean
}

const TileSessionDetailsTime: React.FC<TileSessionDetailsTimeProps> = (props) => {
    if (props.isLive && props.channelId) {
        return (
            <>
                {props.lobby && (
                    <DetailNavLink type="channel" id={props.channelId} name={""}>
                        <LobbySessionTimeDivLive addTopMargin={props.addTopMargin}>
                            <DotLive />
                            <LiveTitle>
                                <h6 style={{ fontSize: "12px", fontFamily: branding.font1 }}>{props.time ?? ""}</h6>
                            </LiveTitle>
                        </LobbySessionTimeDivLive>
                    </DetailNavLink>
                )}
                {!props.lobby && (
                    <SessionRoot>
                        <DetailNavLink type="channel" id={props.channelId} name={""}>
                            <SessionTimeDivLive>
                                <DotLive />
                                <LiveTitle>
                                    <h6 style={{ fontSize: "12px", fontFamily: branding.font1 }}>{props.time ?? ""}</h6>
                                </LiveTitle>
                            </SessionTimeDivLive>
                        </DetailNavLink>
                    </SessionRoot>
                )}
            </>
        )
    } else {
        return (
            <>
                {props.lobby && (
                    <LobbyNextSessionDiv>
                        {vgLive({ stroke: props.color ? props.color : "#fff", width: "20px", height: "20px" })}
                        <LobbyNextSessionTitle>{props.time ?? ""}</LobbyNextSessionTitle>
                    </LobbyNextSessionDiv>
                )}
                {!props.lobby && (
                    <NextSessionDiv>
                        {vgLive({ stroke: props.color ? props.color : "#fff", width: "20px", height: "20px" })}
                        <NextSessionTitle>{props.time ?? ""}</NextSessionTitle>
                    </NextSessionDiv>
                )}
            </>
        )
    }
}
const SessionRoot = styled.div`
    display: flex;
    position: absolute;
    margin-top: 20px;
    justify-content: flex-end;
    right: 60%;
`
/*********************************************************************************************
 * TILE SESSION DETAILS
 **********************************************************************************************/

const SessionDetailsContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
`

const LobbySessionDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SessionDetailsRoot = styled.div<{ color: string }>`
    display: flex;
    color: ${(props) => props.color};
`

interface TileSessionDetailsProps {
    eventdate: EventDate
    isLive: boolean
    time?: string
    color?: string
    addTopMargin: boolean
    lobby?: boolean
}

export const TileSessionDetails: React.FC<TileSessionDetailsProps> = (props) => {
    if (props.eventdate) {
        return (
            <SessionDetailsRoot color={props.color ? props.color : "#fff"}>
                <TileSessionDetailsTime
                    {...props}
                    channelId={props.eventdate.channel?.id}
                    addTopMargin={props.addTopMargin}
                    lobby={props.lobby}
                />
            </SessionDetailsRoot>
        )
    } else {
        return null
    }
}

/*********************************************************************************************
 * MEETING DETAILS
 **********************************************************************************************/

interface MeetingDetailsProps {
    content: any
    color?: string
    lobby?: boolean
}

const MeetingDetails: React.FunctionComponent<MeetingDetailsProps> = (props) => {
    const eventDates: EventDate[] | undefined = (props.content?.eventdates as EventDate[]) || undefined
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const language = languageState.getLanguage()

    if (eventDates) {
        const eventDate = eventDates
            .sort(compareByStartTimeEventDate)
            .find((eventDate) => moment.tz(eventTimezoneName).isBefore(moment(eventDate.date + " " + eventDate.end)))
        if (eventDate) {
            const time = getTimeToLiveString(eventDate, strings, language)
            if (isEventDateLive(eventDate)) {
                return (
                    <>
                        {props.lobby && (
                            <LobbySessionDetailsContainer>
                                <TileSessionDetails
                                    eventdate={eventDate}
                                    isLive={true}
                                    time={time}
                                    color={props.color || "#fff"}
                                    addTopMargin={eventDate.persons.length > 0}
                                    lobby={true}
                                />
                            </LobbySessionDetailsContainer>
                        )}
                        {!props.lobby && (
                            <SessionDetailsContainer>
                                <TileSessionDetails
                                    eventdate={eventDate}
                                    isLive={true}
                                    time={time}
                                    color={props.color || "#fff"}
                                    addTopMargin={eventDate.persons.length > 0}
                                    lobby={false}
                                />
                                {/* <div style={{ marginBottom: "10px" }}>
                            <CrsPersons layoutType={1} showModalPopup={false} persons={eventDate.persons} personsToShow={2} />
                        </div> */}
                            </SessionDetailsContainer>
                        )}
                    </>
                )
            } else {
                return (
                    <div>
                        {props.lobby && (
                            <TileSessionDetails
                                eventdate={eventDate}
                                isLive={false}
                                time={time}
                                color={props.color || "#fff"}
                                addTopMargin={false}
                                lobby={true}
                            />
                        )}
                        {!props.lobby && (
                            <TileSessionDetails
                                eventdate={eventDate}
                                isLive={false}
                                time={time}
                                color={props.color || "#fff"}
                                addTopMargin={false}
                                lobby={false}
                            />
                        )}
                    </div>
                )
            }
        }
    }

    return null
}

export default MeetingDetails
