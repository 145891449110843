import { useEffect, useState, useRef } from "react"
import * as React from "react"
import styled from "styled-components"
import Scrollbar, { ScrollbarProps } from "react-scrollbars-custom"
import ScrollBarComponent from "./ScrollBar"
import { ScrollState } from "react-scrollbars-custom/dist/types/types"
import { device } from "../utils/Device"

const ScrollContainerRoot = styled(Scrollbar)<{ scrollBarAlwaysVisible?: boolean }>`
    & .ScrollbarsCustom-Track {
        opacity: ${(props) => (props.scrollBarAlwaysVisible ? 0.6 : 0.4)};
        transition: opacity 0.4s ease-in-out;
        visibility: ${(props) => (props.scrollBarAlwaysVisible ? "visible" : "hidden")};
        width: 2px !important;

        @media ${device.tablet} {
            width: 8px !important;
        }
    }
    &:hover .ScrollbarsCustom-Track {
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
    }
`

export type ScrollContainerProps = ScrollbarProps & {
    scrollBarAlwaysVisible?: boolean
    customRef?: React.RefObject<HTMLDivElement>
}
const ScrollContainer: React.FC<ScrollContainerProps> = (props) => {
    return React.createElement(
        ScrollContainerRoot,
        { ...props, ref: props.customRef, disableTracksWidthCompensation: true },
        props.children
    )
}
export default ScrollContainer

interface ScrollContainerStyledProps {
    width?: string
    white?: string
    padding?: string
    paddingLeft?: string
    containerwidth?: string
}

// This component has issues: onScroll is sometimes called only once initially and it sometimes displays
// a faulty scroll-bar. I recommend using RoundScrollableDiv instead if you can
const ScrollContainerStyled = styled(ScrollContainer)<ScrollContainerStyledProps>`
    ${(props) => (props.containerwidth ? `width: ${props.containerwidth} !important;}` : "")};
    & .ScrollbarsCustom-Content {
        ${(props) =>
            props.padding ? `padding: ${props.padding};` : `padding: 0 0 60px ${props.paddingLeft ?? "0"} !important;`};
        width: ${(props) => props.width};
    }

    & .ScrollbarsCustom-Track {
        z-index: 2;
        width: 2px !important;

        @media ${device.tablet} {
            width: 8px !important;
        }
    }

    .ScrollbarsCustom-Thumb {
        background-color: ${(props) => (props.white === "true" ? "white !important" : "")};
    }
`

const RoundScrollableDiv = styled.div<{ width?: string; height?: string }>`
    height: ${(props) => (props.height ? props.height : "auto;")};
    width: ${(props) => (props.width ? props.width : "100%;")};
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

const HorizontalRoundScrollableDiv = styled.div<{ width: string; height?: string; myschedule?: boolean }>`
    height: ${(props) => (props.height ? props.height : "auto;")};
    width: ${(props) => (props.width ? props.width : "100%;")};
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
        //height: calc(100% - 20px); //commented out because it caused schedule to break on iPads
        background-color: transparent;
        border-radius: 4px;
        ${(props) => (props.myschedule ? "display: none;" : "")}
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.4);
        }
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    @media (max-width: 640px) {
        width: 100%;
    }
`

const RoundScrollbarParent = styled.div<{ startScrolling: boolean; offset: number; myschedule?: boolean }>`
    ${(props) => "width: calc(100% - " + props.offset + "px);"}
    ${(props) => (props.myschedule ? "margin-bottom: 1px;" : "margin-bottom: 20px;")}
`

const HorizontalScrollbarKiller = styled.div<{ scrollbarVisible: boolean }>`
    position: absolute;
    width: 100%;
    height: 15px;
    background: #fff;
    bottom: ${(props) => (props.scrollbarVisible ? "20px" : 0)};
    left: 0;
    right: 0;
    z-index: 2;
`

interface ContentScrollContainerProps {
    adjustForHeaderWith?: string
    noScrollX?: boolean
    width?: string
    containerWidth?: string
    paddingLeft?: string
    padding?: string
    scrollType?: string
    white?: string
    handleScroll?(value: number): void
    isVerticallyScrollable?(scrollabel: boolean): void
    roundHorizontalScrollbar?: boolean
    scrollBarAlwaysVisible?: boolean
    setIsScrollable?: (isScrollable: boolean) => void
    mySchedule?: boolean
}
export const ContentScrollContainer: React.FC<ContentScrollContainerProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(0)
    const [value, setValue] = useState(0)
    const [scrollValues, setScrollValues] = useState<ScrollState>()

    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!
    let lastY = props.roundHorizontalScrollbar ? scrollRef?.current?.scrollTop! : 0

    useEffect(() => {
        if (
            props.setIsScrollable &&
            scrollRef &&
            scrollRef.current &&
            scrollRef.current.scrollHeight &&
            scrollRef.current.clientHeight
        ) {
            props.setIsScrollable(scrollRef.current?.scrollHeight! >= scrollRef.current?.clientHeight!)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (props.roundHorizontalScrollbar) {
            scrollRef.current?.scrollTo(position, lastY)

            const timeout = setTimeout(() => {
                setIsLoaded(true)
            }, 1000)

            return () => clearTimeout(timeout)
        } else scrollRef.current?.scrollTo(0, position)

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position])

    useEffect(() => {
        if (!!props.isVerticallyScrollable) {
            if (scrollValues) {
                props.isVerticallyScrollable(scrollValues.contentScrollHeight > scrollValues.clientHeight)
            }
        }
        // eslint-disable-next-line
    }, [scrollValues])

    if (props.roundHorizontalScrollbar) {
        const scrollRange = scrollRef.current?.scrollWidth! - scrollRef.current?.offsetWidth!
        return (
            <>
                <RoundScrollbarParent
                    startScrolling={scrollRef?.current?.scrollTop! > 20}
                    offset={props.mySchedule ? 3 : 50}
                    myschedule={props.mySchedule}
                >
                    <ScrollBarComponent
                        value={value}
                        maxScrollRange={scrollRange}
                        onScroll={(e: any) => {
                            setPosition(e)
                            setValue(e)
                        }}
                    />
                </RoundScrollbarParent>
                <HorizontalRoundScrollableDiv
                    ref={scrollRef}
                    onScroll={(e: any) => {
                        if (isLoaded) {
                            const currY = e.currentTarget.scrollTop
                            const y = currY > lastY ? "down" : currY === lastY ? "none" : "up"
                            if (props.handleScroll && (y === "down" || y === "up")) {
                                props.handleScroll(e.currentTarget)
                            }
                            setValue(scrollRef.current?.scrollLeft!)
                            lastY = currY
                        } else {
                            scrollRef.current?.scrollTo(0, lastY)
                            setValue(0)
                        }
                    }}
                    width={props.width!}
                    height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}
                    myschedule={props.mySchedule}
                >
                    {props.children}
                    <HorizontalScrollbarKiller scrollbarVisible={scrollRange > 0} />
                </HorizontalRoundScrollableDiv>
            </>
        )
    } else
        return (
            <>
                {props.scrollType === "round" ? (
                    <>
                        <RoundScrollableDiv
                            id="round-scrollable-div"
                            ref={scrollRef}
                            onScroll={(e: any) => {
                                setValue(scrollRef.current?.scrollTop!)
                                if (props.handleScroll) {
                                    props.handleScroll(scrollRef.current ? scrollRef.current : e)
                                }
                            }}
                            width={props.width}
                            height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}
                        >
                            {props.children}
                        </RoundScrollableDiv>
                        <ScrollBarComponent
                            value={value}
                            maxScrollRange={maxScrollRange ? maxScrollRange : 0}
                            onScroll={(val: number) => {
                                setPosition(val)
                                setValue(val)
                            }}
                            isVertical={true}
                        ></ScrollBarComponent>
                    </>
                ) : (
                    <ScrollContainerStyled
                        customRef={scrollRef}
                        scrollBarAlwaysVisible={props.scrollBarAlwaysVisible}
                        onScroll={(e: any) => {
                            if (props.handleScroll) props.handleScroll(e)
                        }}
                        onUpdate={(scrollValues, prevScrollState) => {
                            setScrollValues(scrollValues)
                        }}
                        noScrollX={props.noScrollX}
                        style={{
                            height: props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"
                        }}
                        {...props}
                    >
                        {props.children}
                    </ScrollContainerStyled>
                )}
            </>
        )
}

ContentScrollContainer.defaultProps = {
    noScrollX: true,
    width: "100%",
    white: "false"
} as Partial<ContentScrollContainerProps>
