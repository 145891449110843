import { useState, useEffect } from "react"
import * as React from "react"
import {
    EditProfileProps,
    ProfileRoot,
    ProfileSectionRoot,
    EditProfileLabel,
    EditProfileInformationField,
    SaveChangesButton,
    ErrorMessageContainer,
    SaveChangesSectionRoot,
    SaveChangesButtonContainer,
    ProfileSectionsContainer
} from "./MobileMyProfilePageContent"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { useLanguageState } from "../../globalStates/LanguageState"
import {
    DeleteProfile,
    getUserPayload,
    isPhoneNumberCorrect,
    isImageSizeValid,
    areRequiredFieldsEmpty,
    trimValues,
    ProfileImage,
    ProfileInformation,
    ProfileCategories,
    isValidationCorrect,
    RequiredFieldLabel,
    RequiredInterestsWarning,
    getEditFields,
    getSocialMediaFields,
    RadioFieldsContainer
} from "./EditMyProfileLayout"
import branding from "../../branding/branding"
import styled from "styled-components"
import { Field, CategoryType, EditTab, BasicInfoLanguage } from "./MyProfilePageContentBranding"
import { defaultLogger as logger } from "../../globalStates/AppState"
import CenteredLoader from "../../ui/CenteredLoader"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { CategoryData } from "./ViewMyProfileLayout"
import { Category } from "../../backendServices/Types"
import { Row } from "react-bootstrap"
import { EditProfileSection } from "./MyProfilePageContent"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { updateUserValues } from "../../backendServices/GraphQLServices"
import {
    updateMyProfileData,
    deleteProfileImage,
    changeProfileImage,
    getInterest,
    SelfDefinedUserRole,
    getSelfDefinedUserRolesByTopic
} from "../../backendServices/SeriesOfTopicsUserServices"
import RadioField from "../../ui/Radio"

const MobileEditMyProfileLayout: React.FunctionComponent<EditProfileProps> = (props: EditProfileProps) => {
    const loggedInUser = useLoggedInState().user()

    let userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const [user, setUser] = useState<User>(userState.user()!)

    const [userRoles, setUserRoles] = useState<SelfDefinedUserRole[]>([])

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)
    const [imageFile, setImageFile] = useState<any>(null)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [isProfileImageChanged, setIsProfileImageChanged] = useState<boolean>(false)
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false)

    const [basicInfoLang, setBasicInfoLang] = useState<string>(lang)
    const [alertMessage, setAlertMessage] = useState<string>("")

    const itemsOrder = branding.myProfilePageContent.editProfileTabs

    const [categoryData, setCategoryData] = useState<CategoryData>({
        interests: [],
        offerings: [],
        lookingfor: [],
        selectedInterests: [],
        selectedOfferings: [],
        selectedLookingfor: []
    })

    function updateValues() {
        updateMyProfileData({
            profileId: userState.user()!.profileId,
            profileData: getUserPayload(userState, user, categoryData)
        }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({
                id: user.profileId,
                name: userName,
                pictureUrl: user.logoUrl,
                presenceStatus: user.presence,
                lastConnected: new Date().toISOString()
            })

            setIsLoaded(true)
            setSuccess(true)
            setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setAlertMessage("")
        setIsLoaded(false)

        if (areRequiredFieldsEmpty(user, branding.myProfilePageContent.editProfileFieldsList, basicInfoLang)) {
            setSuccess(false)
            setIsLoaded(true)

            setAlertMessage(strings.myProfilePageContent.requiredFieldsEmptyWarning)

            return
        }

        if (isProfileImageChanged) {
            if (!isImageSizeValid(imageSize, imageDimensions)) {
                setSuccess(false)
                setIsLoaded(true)

                setAlertMessage(strings.myProfilePageContent.pictureSizeWarning)
                return
            }
        }

        if (!isPhoneNumberCorrect(user?.phone || "", Field.PHONE) || !isPhoneNumberCorrect(user?.mobile || "", Field.MOBILE)) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.phoneValidationIncorrectWarning)

            return
        }

        if (!isValidationCorrect(user!)) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.validationIncorrectWarning)

            return
        }

        if (isProfileImageDeleted) {
            userState.setProfileImage("")
            setUser({ ...user, logoUrl: "" })

            deleteProfileImage(userState.user()?.profileId || "")
                .then((res) => {
                    userState.setProfileImage("")
                    setUser({ ...user, logoUrl: "" })
                    updateValues()
                })
                .catch((error) => {
                    logger.error({ message: "EditMyProfileLayout Error", errorMessage: error.message, errorStack: error.stack })
                })

            return
        } else if (isProfileImageChanged) {
            if (imageFile && imageFile !== null) {
                let imageData = new FormData()
                imageData.append("image", imageFile)

                changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData })
                    .then((res) => {
                        if (res.logoUrl) {
                            userState.setProfileImage(res.logoUrl || "")
                            setUser({ ...user, logoUrl: res.logoUrl })

                            updateValues()
                        }
                    })
                    .catch((error) => {
                        logger.error({
                            message: "EditMyProfileLayout Profile Logo Upload failed",
                            errorMessage: error.message,
                            errorStack: error.stack
                        })
                    })
            }

            return
        } else {
            updateValues()
        }
    }

    function getSection() {
        if (props.section) {
            const contactSection = (
                <ContactSection
                    setMyProfileMode={props.setMyProfileMode}
                    user={user}
                    setUser={setUser}
                    userRoles={userRoles || []}
                    setImageDimensions={setImageDimensions}
                    setImageSize={setImageSize}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    setIsProfileImageChanged={setIsProfileImageChanged}
                    setIsProfileImageDeleted={setIsProfileImageDeleted}
                    basicInfoLang={basicInfoLang}
                    setBasicInfoLang={setBasicInfoLang}
                />
            )

            const socialsSection = <SocialsSection user={user} setUser={setUser} />

            const interestsSection = <InterestsSection categoryData={categoryData} setCategoryData={setCategoryData} />

            const offersNeedsSection = <OffersNeedsSection categoryData={categoryData} setCategoryData={setCategoryData} />

            const deleteProfileSection = (
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                    <DeleteProfile />
                </div>
            )

            switch (props.section) {
                case EditProfileSection.CONTACT:
                    return (
                        <>
                            {contactSection}

                            {deleteProfileSection}
                        </>
                    )

                case EditProfileSection.SOCIALS:
                    return <>{socialsSection}</>

                case EditProfileSection.INTERESTS:
                    return <>{interestsSection}</>

                case EditProfileSection.LOOKINGFORANDOFFERING:
                    return <>{offersNeedsSection}</>

                case EditProfileSection.ALL:
                    return (
                        <>
                            {itemsOrder.map((item: EditTab) => {
                                switch (item) {
                                    case EditTab.BASIC_INFO: {
                                        return <>{contactSection}</>
                                    }

                                    case EditTab.SOCIAL_MEDIA: {
                                        return <>{socialsSection}</>
                                    }

                                    case EditTab.INTERESTS: {
                                        return <>{interestsSection}</>
                                    }

                                    case EditTab.BUY_SELL: {
                                        return <>{offersNeedsSection}</>
                                    }

                                    default:
                                        return <div />
                                }
                            })}

                            {deleteProfileSection}
                        </>
                    )

                default:
                    return <div />
            }
        }

        return <div />
    }

    function getData() {
        if (
            props.section === EditProfileSection.INTERESTS ||
            props.section === EditProfileSection.LOOKINGFORANDOFFERING ||
            props.section === EditProfileSection.ALL
        ) {
            getInterest().then((res) => {
                if (res.content.interests) {
                    const interests: Category[] = res.content.interests ?? []
                    const offerings: Category[] = res.content.lookingforandoffering ?? []
                    const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                    const selectedInterests = interests.filter((i) => (loggedInUser?.interests ?? []).includes(i.id))
                    const selectedOfferings = offerings.filter((i) => (loggedInUser?.offering ?? []).includes(i.id))
                    const selectedLookingfor = lookingfor.filter((i) => (loggedInUser?.lookingfor ?? []).includes(i.id))
                    setCategoryData({
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selectedOfferings,
                        selectedLookingfor: selectedLookingfor
                    })
                }
            })

            getSelfDefinedUserRolesByTopic().then((res) => {
                if (res.content.selfDefinedUserRoles) {
                    setUserRoles(res.content.selfDefinedUserRoles)
                }
            })
        }
    }

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ProfileRoot className="editProfile">
            <ProfileSectionsContainer>{getSection()}</ProfileSectionsContainer>
            <SaveChangesSection
                isLoaded={isLoaded}
                success={success}
                alertMessage={alertMessage}
                onClick={() => {
                    trimValues(user, setUser)
                    handleDataChange()
                }}
            />
        </ProfileRoot>
    )
}

interface SaveChangesSectionProps {
    isLoaded: boolean
    success: boolean
    alertMessage: string
    onClick: () => void
}

const SaveChangesSection: React.FunctionComponent<SaveChangesSectionProps> = (props: SaveChangesSectionProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <SaveChangesSectionRoot>
            <div style={{ height: "40px", marginTop: "20px", marginBottom: "10px" }}>
                <div style={{ visibility: props.isLoaded ? "hidden" : "visible" }}>
                    <SaveLoader />
                </div>
            </div>
            <ErrorMessageContainer success={props.success}>{props.alertMessage}</ErrorMessageContainer>
            <SaveChangesButtonContainer>
                <SaveChangesButton
                    onClick={() => {
                        props.onClick()
                    }}
                >
                    {strings.myProfilePageContent.saveChangesMobileTitle}
                </SaveChangesButton>
            </SaveChangesButtonContainer>
        </SaveChangesSectionRoot>
    )
}

const SaveLoader = styled(CenteredLoader)`
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const SelectThemeCustom = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

interface ContactSectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
}

const DisplayProfileLangLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-right: auto;
    display: inline-block;
`

interface ContactSectionProps {
    user: User
    setUser: (value: User) => void

    userRoles: SelfDefinedUserRole[]

    setImageDimensions: (value: any) => void

    setImageSize: (value: number) => void

    imageFile: any
    setImageFile: (value: any) => void

    setIsProfileImageChanged: (value: boolean) => void
    setIsProfileImageDeleted: (value: boolean) => void

    basicInfoLang: string
    setBasicInfoLang: (value: string) => void
}

const ContactSection: React.FunctionComponent<ContactSectionProps> = (props: ContactSectionProps) => {
    const strings = useLanguageState().getStrings()
    const fieldsList = branding.myProfilePageContent.editProfileFieldsList

    return (
        <ProfileSectionRoot style={{ paddingBottom: "60px" }}>
            <ProfileImage
                user={props.user}
                setUser={props.setUser}
                setIsProfileImageDeleted={props.setIsProfileImageDeleted}
                setIsProfileImageChanged={props.setIsProfileImageChanged}
                setImageDimensions={props.setImageDimensions}
                setImageSize={props.setImageSize}
                setImageFile={props.setImageFile}
                mobile
            />

            {branding.myProfilePageContent.basicInfoLanguagesList.length > 1 && (
                <Row style={{ marginLeft: "20px", marginTop: "25px", marginRight: "20px" }}>
                    <DisplayProfileLangLabel>{strings.myProfilePageContent.displayProfileLangLabel}</DisplayProfileLangLabel>
                    {
                        <RadioFieldsContainer>
                            {branding.myProfilePageContent.basicInfoLanguagesList.map(
                                (item: BasicInfoLanguage, index: number) => {
                                    return (
                                        <RadioField
                                            key={index}
                                            id={item.value}
                                            name={item.label}
                                            value={item.value}
                                            radioValue={props.basicInfoLang}
                                            label={item.label}
                                            textColor={branding.sideIconBar.sideIconColorDark}
                                            onChange={() => props.setBasicInfoLang(item.value)}
                                        />
                                    )
                                }
                            )}
                        </RadioFieldsContainer>
                    }
                </Row>
            )}
            <Row style={{ marginLeft: "5px", marginRight: "5px" }}>
                {getEditFields(fieldsList, props.user!, props.setUser, strings, props.basicInfoLang, props.userRoles, true)}
            </Row>
            {fieldsList.includes(Field.BIOGRAPHY) && (
                <Row style={{ marginRight: "5px", marginLeft: "5px" }}>
                    <ProfileInformation
                        key={Field.BIOGRAPHY}
                        labelText={
                            props.basicInfoLang === "en"
                                ? strings.myProfilePageContent.biographyLabel
                                : strings.myProfilePageContent.biographyDeLabel
                        }
                        placeholder={
                            props.basicInfoLang === "en"
                                ? strings.myProfilePageContent.biographyPlaceholder
                                : strings.myProfilePageContent.biographyDePlaceholder
                        }
                        value={(props.basicInfoLang === "en" ? props.user?.infotext : props.user?.infotextDe) || ""}
                        updateValue={(value: string) =>
                            props.setUser(
                                props.basicInfoLang === "en"
                                    ? { ...props.user!, infotext: value }
                                    : { ...props.user!, infotextDe: value }
                            )
                        }
                        fieldMarker={Field.BIOGRAPHY}
                        multiLine
                    />
                </Row>
            )}
        </ProfileSectionRoot>
    )
}

interface SocialsSectionProps {
    user: User
    setUser: (value: User) => void
}
const SocialsSection: React.FunctionComponent<SocialsSectionProps> = (props: SocialsSectionProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <ProfileSectionRoot style={{ paddingBottom: "30px" }}>
            <EditProfileInformationField>
                <EditProfileLabel>{strings.myProfilePageContent.socialMediaSectionTitle}</EditProfileLabel>
                {getSocialMediaFields(props.user!, props.setUser, strings, true)}
            </EditProfileInformationField>
        </ProfileSectionRoot>
    )
}
interface CategoriesSectionProps {
    categoryData: CategoryData
    setCategoryData: (value: CategoryData) => void
}

const InterestsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    const strings = useLanguageState().getStrings()
    const requiredInterestsNumber = branding.myProfilePageContent.requiredInterestsNumber
    const required = requiredInterestsNumber > 0

    return (
        <ProfileSectionRoot style={{ paddingBottom: "30px" }}>
            <EditProfileInformationField>
                <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>
                    {strings.myProfilePageContent.yourInterestsSectionTitle}{" "}
                    {required && <RequiredFieldLabel>*</RequiredFieldLabel>}
                </EditProfileLabel>
                {requiredInterestsNumber > 0 && (
                    <RequiredInterestsWarning>
                        {requiredInterestsNumber === 1
                            ? strings.myProfilePageContent.requiredInterestsWarningSingle
                            : strings.myProfilePageContent.requiredInterestsWarningMultiple.replace(
                                  "${number}",
                                  requiredInterestsNumber.toString()
                              )}
                    </RequiredInterestsWarning>
                )}
                <ProfileCategories
                    type={CategoryType.INTERESTS}
                    setCategoryBindings={(newInterests) =>
                        props.setCategoryData({ ...props.categoryData, selectedInterests: newInterests })
                    }
                    data={props.categoryData}
                    required={required}
                />
            </EditProfileInformationField>
        </ProfileSectionRoot>
    )
}

const OffersNeedsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    const strings = useLanguageState().getStrings()

    const [offersSectionTitle, setOffersSectionTitle] = useState<string>(strings.myProfilePageContent.imOfferingLabel)

    const [needsSectionTitle, setNeedsSectionTitle] = useState<string>(strings.myProfilePageContent.imLookingToBuyLabel)

    return (
        <ProfileSectionRoot style={{ paddingBottom: "30px" }}>
            <EditProfileInformationField style={{ borderBottom: branding.sideIconBar.mobileNavigationBorder }}>
                <EditProfileLabel>{offersSectionTitle}</EditProfileLabel>
                <ProfileCategories
                    type={CategoryType.OFFERS}
                    setCategoryBindings={(newOffers) =>
                        props.setCategoryData({ ...props.categoryData, selectedOfferings: newOffers })
                    }
                    data={props.categoryData}
                    limitNumberOfSelectedCategories={true}
                    setSectionTitle={setOffersSectionTitle}
                />
            </EditProfileInformationField>

            <EditProfileInformationField>
                <EditProfileLabel>{needsSectionTitle}</EditProfileLabel>
                <ProfileCategories
                    type={CategoryType.NEEDS}
                    setCategoryBindings={(newLookingfor) =>
                        props.setCategoryData({ ...props.categoryData, selectedLookingfor: newLookingfor })
                    }
                    data={props.categoryData}
                    limitNumberOfSelectedCategories={true}
                    setSectionTitle={setNeedsSectionTitle}
                />
            </EditProfileInformationField>
        </ProfileSectionRoot>
    )
}

export default MobileEditMyProfileLayout
