import * as React from "react"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Category } from "../backendServices/Types"
import branding from "../branding/branding"
import { getActiveLanguage } from "../globalStates/LanguageState"
import { globalSearchResultPageRoute } from "../navigationArea/RoutePaths"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { sortAlphabeticallyIgnoreCase } from "../utils/StringUtils"
import useWindowDimensions from "./WindowDimensionsHook"

export type BadgeType = "A" | "B"

const FlexBadgeArea = styled.div<{ marginTop?: string }>`
    display: flex;
    flex-wrap: wrap;
`
interface CategoryBadgeProps {
    bgImage: string
    bgColor: string
    borderColor: string
    textColor: string
    marginTop?: string
    marginBottom?: string
    fontSize?: string
}

export const CategoryBadge = styled.div<CategoryBadgeProps>`
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "5px")};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "5px")};
    font-family: ${branding.font1};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    margin-right: 5px;
    padding: 5px 12px 5px 12px;
    height: 30px;
    text-align: center;
    border-style: solid;
    border-radius: 5px;
    border-width: thin;
    background-color: ${(props) => props.bgColor};
    border-color: ${(props) => props.borderColor};
    color: ${(props) => props.textColor};
    cursor: pointer;

    &.profile {
        padding: 5px;
        margin-right: 10px;
    }

    &.communicationArea {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        font-size: 10px;
        border: 1px solid;
        border-radius: 5px;
        margin-top: 0px;
        margin-right: 3px;
        padding-right: 3px;
        padding-left: 3px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 5px;
        font-family: ${branding.font1};
        height: 20px;
    }

    &.person {
        cursor: auto;
    }
`

export const CategoryImg = styled.img`
    display: inline-block;
    height: 18px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: -5px;
`

export const CategoryText = styled.div`
    display: inline-block;
    position: relative;
    bottom: 1px;

    &.noIcon {
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &.communicationArea {
            top: 48%;
            transform: translateY(-45%);
        }
    }

    &.withIcon {
        padding-left: 8px;
    }
`

interface BadgeListProps {
    categories: Category[]
    marginTop?: string
    marginBottom?: string
    fontSize?: string
    /**
     * Defines the max number of badges which should be displayed
     */
    maxBadgeCount?: number
}
export const BadgeArea: React.FC<BadgeListProps> = (props) => {
    const suggestState = useSuggestContext()
    const history = useHistory()
    const [categoryBadges, setCategoryBadges] = useState<any[]>([])
    const { isMobile } = useWindowDimensions()
    const appLanguage = getActiveLanguage()

    const onCategorySelect = (category: Category | any) => {
        if ((category.key || category.id) && (category.label || category.name)) {
            suggestState.addSuggestion({
                icon: "",
                id: "",
                subtitle: "",
                title: category.label || category.name,
                value: "cat_" + (category.key || category.id),
                active: true
            })
            history.push(globalSearchResultPageRoute)
        }
    }

    useEffect(() => {
        let categoryBadgesTemp: Category[] = []
        categoryBadgesTemp = props.categories.filter(({ badgeType }: Category) => badgeType && badgeType === "A")

        // sort the categories by order key
        if (appLanguage === "en") {
            categoryBadgesTemp = categoryBadgesTemp.sort((a: Category, b: Category) => {
                return sortAlphabeticallyIgnoreCase(a.orderkey, b.orderkey)
            })
        } else if (appLanguage === "de") {
            categoryBadgesTemp = categoryBadgesTemp.sort((a: Category, b: Category) => {
                return sortAlphabeticallyIgnoreCase(a.orderkeyDe || a.orderkey, b.orderkeyDe || b.orderkey)
            })
        }

        // If maxBadgeCount is defined, get the first n categories
        if (props.maxBadgeCount) {
            setCategoryBadges(categoryBadgesTemp.slice(0, props.maxBadgeCount))
        } else {
            setCategoryBadges(categoryBadgesTemp)
        }
        // eslint-disable-next-line
    }, [props.categories])

    if (isMobile) {
        return null
    }

    if (branding.categoryBadgesVisible && props.categories && props.categories.length > 0)
        return (
            <FlexBadgeArea>
                {categoryBadges.map((category) => {
                    return (
                        <CategoryBadge
                            key={category.id + category.badgeName}
                            bgImage=""
                            bgColor={category.colorFill}
                            borderColor={category.colorBorder}
                            textColor={category.colorText}
                            marginTop={props.marginTop}
                            marginBottom={props.marginBottom}
                            fontSize={props.fontSize}
                            onClick={(e: any) => {
                                onCategorySelect(category)

                                e.stopPropagation()
                                e.preventDefault()
                            }}
                        >
                            {category.iconSmallUrl && (
                                <CategoryImg key={category.id + category.badgeName + "img"} src={category.iconSmallUrl} />
                            )}

                            <CategoryText className={category.iconSmallUrl ? "withIcon" : "noIcon"}>
                                {category.badgeName}
                            </CategoryText>
                        </CategoryBadge>
                    )
                })}
            </FlexBadgeArea>
        )
    else return null
}
export default BadgeArea
