import { useEffect, useState } from "react"
import * as React from "react"
import { AccessProvider, ConferenceRoom, EventDateBase, RestrictedArea } from "../../backendServices/Types"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess, RestrictedAreaAccessType, determineType } from "../../globalStates/UserRestrictedAreaAccess"
import styled from "styled-components"
import branding, { Branding, getPluralString } from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { hasAccessToOrganization } from "../../utils/Authorization"
import CenteredLoader from "../../ui/CenteredLoader"
import { useChimeContext } from "../../conference/context/ChimeContext"
import moment from "moment"
import { EventDateTiming } from "../detailPages/EventDateDetailPageContent"
import { getNow } from "../../globalStates/EventState"
import RequestAccessModal from "../../ui/modals/RequestAccessModal"
import { RestrictedAreaTypes } from "../../backendServices/RestrictedAreaServices"
import { MeetingRoomGroupType } from "../../conference/AudioVideoBranding"
import { useParams } from "react-router-dom"

const ActionButtonRoot = styled.div<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    ${(props) => (props.disabled ? "pointer-events: none; cursor: default;" : "cursor: pointer;")}
    line-height: 17px;
    font-family: ${branding.font1};

    &.private-event-light-player-button {
        padding: 10px;
        width: 200px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 5px;
        border: 1px solid white;
        color: black;
        background-color: white;
        z-index: 1000;
        font-size: ${branding.receptionPage.virtualCafeAccessButtonFontSize ?? "12px"};

        :hover {
            color: ${(props) => (props.disabled ? "#000" : "#FFF")};
            background-color: ${(props) =>
                props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover};
            border-color: ${(props) => (props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover)};
            text-decoration: none;
        }
    }

    &.private-event-dark-box-button {
        width: 100%;
        height: 100%;
        background-color: ${(props) => (props.disabled ? "rgba(0, 0, 0, .5)" : "#000")};
        color: ${(props) => (props.disabled ? "#CCC" : "#FFF")};
        border-radius: 5px;
        font-size: 16px;

        :hover {
            color: ${(props) => (props.disabled ? "#000" : "#FFF")};
            background-color: ${(props) =>
                props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover};
            border-color: ${(props) => (props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover)};
        }
    }

    &.meeting-room {
        width: 140px;
        height: 30px;
        position: absolute;
        right: 20px;
        border: 1px solid white;
        border-radius: 5px;
        color: black;
        background-color: white;
        cursor: ${(props) => (props.disabled ? "default" : "pointer")};
        font-size: ${branding.receptionPage.virtualCafeAccessButtonFontSize ?? "12px"};

        :hover {
            background-color: ${(props) =>
                props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover};
            border-color: ${(props) => (props.disabled ? "none" : branding.meetingRoomGroupsRequestAccessButtonBgColorOnHover)};
            color: ${(props) => (props.disabled ? "black" : `white`)};
            text-decoration: none;
        }
    }
`

const ActionButtonText = styled.p`
    margin-bottom: 0rem;
    z-index: 3;
`

const SubmitModalButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    right: 60%;
    cursor: pointer;
    width: 300px;
    height: 50px;
    border-radius: 30px;
    background-color: black;
    border: 1px solid transparent;
    transition: background-color 0.3s, border-color 0.3s;
    color: white;
    padding: 0.5rem;
    text-align: center;
    &:hover {
        color: black;
        background-color: white;
        border: 1px solid black;
    }
`

const ConferenceRoomSubmitModalAccessMessage = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 50%;
    right: 60%;
    width: 300px;
`

export enum ActionButtonTheme {
    PRIVATE_EVENT_LIGHT_PLAYER,
    PRIVATE_EVENT_DARK_BOX,
    CONFERENCE_PAGE_SUBMIT_MODAL,
    MEETING_ROOM // not currently in use, is determined by type of props.restrictedArea for meeting rooms, i.e. virtual cafes and conference rooms
}

interface ActionButtonProps {
    restrictedArea: RestrictedArea
    setShowGuestModal?: (value: boolean) => void
    theme: ActionButtonTheme
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const [showRequestAccessModal, setShowRequestAccessModal] = useState<boolean>(false)
    const userAccessState = useUserRestrictedAreaAccess()
    const isAccessLoaded = userAccessState.isLoaded(props.restrictedArea)

    useEffect(() => {
        // safety-fallback: time-out to allow fetchUserAccessForAllRestrictedAreaTypes() and the likes to go first,
        // to hopefully make this backend request not necessary,
        // it is however necessary for accesses that have not been requested or granted yet, because they don't have
        // entries in the table user<eventdate | virtualcafe | conferenceroom >access yet

        if (!isAccessLoaded) {
            const handle = setTimeout(() => {
                if (!isAccessLoaded) userAccessState.fetchUserAccessForSingleRestrictedArea(props.restrictedArea)
            }, 3000)
            return () => clearTimeout(handle)
        }

        // eslint-disable-next-line
    }, [isAccessLoaded]) //eslint-disable-line

    const type = determineType(props.restrictedArea)
    let actionButton = null
    if (type === RestrictedAreaTypes.PrivateEvent) {
        actionButton = (
            <ActionButtonPrivateEvent
                eventDate={props.restrictedArea as EventDateBase}
                setShowGuestModal={props.setShowGuestModal}
                setShowRequestAccessModal={setShowRequestAccessModal}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                theme={props.theme}
            />
        )
    } else if (props.theme === ActionButtonTheme.CONFERENCE_PAGE_SUBMIT_MODAL) {
        actionButton = (
            <ActionButtonConferencePageSubmitModal
                meetingRoom={props.restrictedArea as MeetingRoomGroupType}
                setShowRequestAccessModal={setShowRequestAccessModal}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        )
    } else if (type === RestrictedAreaTypes.VirtualCafe || type === RestrictedAreaTypes.ConferenceRoom) {
        actionButton = (
            <ActionButtonMeetingRoom
                meetingRoom={props.restrictedArea as MeetingRoom}
                setShowGuestModal={props.setShowGuestModal}
                setShowRequestAccessModal={setShowRequestAccessModal}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        )
    }

    if (isVisible) {
        return (
            <>
                {actionButton}
                {showRequestAccessModal && (
                    <RequestAccessModal
                        restrictedArea={props.restrictedArea}
                        onHide={() => {
                            setShowRequestAccessModal(false)
                        }}
                    ></RequestAccessModal>
                )}
            </>
        )
    } else {
        return null
    }
}

type MeetingRoom = MeetingRoomGroupType | ConferenceRoom
interface ActionButtonMeetingRoomProps {
    meetingRoom: MeetingRoom
    setShowGuestModal?: (value: boolean) => void
    setShowRequestAccessModal: (value: boolean) => void
    isVisible: boolean
    setIsVisible: (value: boolean) => void
}

const ActionButtonMeetingRoom: React.FC<ActionButtonMeetingRoomProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const className = "meeting-room"
    const loggedInUser = useLoggedInState().user()
    const userAccessState = useUserRestrictedAreaAccess()
    const isMeetingRoomUnlocked = userAccessState.isUnlocked(props.meetingRoom)
    const isAccessLoaded = userAccessState.isLoaded(props.meetingRoom)
    const accessStatus: RestrictedAreaAccessType = userAccessState.getStatus(props.meetingRoom)
    let actionButtonField = null

    useEffect(() => {
        setActionButtonField()
        // eslint-disable-next-line
    }, [accessStatus, isAccessLoaded])

    const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        if (
            !loggedInUser ||
            !hasAccessToOrganization(
                loggedInUser,
                (props.meetingRoom as MeetingRoomGroupType).organizationId ?? (props.meetingRoom as ConferenceRoom).organization
            )
        ) {
            if (props.setShowGuestModal) props.setShowGuestModal(true)
            return
        }

        props.setShowRequestAccessModal(true)
    }

    function setActionButtonField() {
        if (isMeetingRoomUnlocked) {
            if (props.isVisible) props.setIsVisible(false)
        } else {
            if (!props.isVisible) props.setIsVisible(true)
        }
        if (!isAccessLoaded) {
            actionButtonField = (
                <ActionButtonRoot className={className} disabled={true}>
                    <CenteredLoader size="xs" />
                </ActionButtonRoot>
            )
        } else if (isAccessLoaded) {
            if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                actionButtonField = (
                    <ActionButtonRoot className={className} disabled={true}>
                        <ActionButtonText>{strings.globalTexts.requestSent}</ActionButtonText>
                    </ActionButtonRoot>
                )
            } else {
                actionButtonField = (
                    <ActionButtonRoot onClick={onRequestAccessClick} className={className}>
                        <ActionButtonText>{strings.globalTexts.requestAccess}</ActionButtonText>
                    </ActionButtonRoot>
                )
            }
        }
    }
    setActionButtonField()
    return <>{actionButtonField}</>
}
interface ActionButtonPrivateEventProps {
    eventDate: EventDateBase
    setShowGuestModal?: (value: boolean) => void
    setShowRequestAccessModal: (value: boolean) => void
    isVisible: boolean
    setIsVisible: (value: boolean) => void
    theme: ActionButtonTheme
}

const ActionButtonPrivateEvent: React.FC<ActionButtonPrivateEventProps> = (props) => {
    const loggedInUser = useLoggedInState().user()
    const userAccessState = useUserRestrictedAreaAccess()
    const isEventUnlocked = userAccessState.isUnlocked(props.eventDate)
    const accessStatus = userAccessState.getStatus(props.eventDate)
    const strings = useLanguageState().getStrings()
    const chime = useChimeContext()
    let startTime = moment(props.eventDate.startdatetime)
    let endTime = moment(props.eventDate.enddatetime)
    const [eventDateTiming, setEventDateTiming] = useState<EventDateTiming>()
    const [roundTableLiveCountdown, setRoundTableLiveCountdown] = useState<number>(-1)
    const [roundTableLiveCountdownActive, setRoundTableLiveCountdownActive] = useState<boolean>(false)
    const isRoundTable = props.eventDate.type === "roundtable"

    let className = ""
    if (props.theme === ActionButtonTheme.PRIVATE_EVENT_DARK_BOX) className = "private-event-dark-box-button"
    else className = "private-event-light-player-button"

    if (isRoundTable) {
        startTime = startTime.subtract(branding.configuration.roundTableOpenBeforeMinutes ?? 5, "minutes")
        endTime = endTime.add(branding.configuration.roundTableOpenAfterMinutes ?? 5, "minutes")
    }

    // event date timing phases
    useEffect(() => {
        const now = getNow()
        if (now.isBefore(startTime)) {
            setEventDateTiming(EventDateTiming.BEFORE)
            const timeToLive = startTime.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.LIVE)
                }, timeToLive)
                return () => clearTimeout(handle)
            }
        } else if (now.isBefore(endTime)) {
            setEventDateTiming(EventDateTiming.LIVE)
            const timeToEnd = endTime.diff(now)
            if (timeToEnd < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.AFTER)
                }, timeToEnd)
                return () => clearTimeout(handle)
            }
        } else {
            setEventDateTiming(EventDateTiming.AFTER)
        }
    }, [props.eventDate.id]) // eslint-disable-line

    // timer
    useEffect(() => {
        if (eventDateTiming === EventDateTiming.BEFORE) {
            const now = getNow()
            const timeToLive = startTime.diff(now)
            setRoundTableLiveCountdown(timeToLive)
        }
    }, [eventDateTiming]) // eslint-disable-line

    useEffect(() => {
        if (roundTableLiveCountdownActive) {
            if (eventDateTiming === EventDateTiming.BEFORE && roundTableLiveCountdown > 0) {
                const now = getNow()
                const updatedTimeToLive = startTime.diff(now)
                const tickLength = updatedTimeToLive % 1000 === 0 ? 1000 : updatedTimeToLive % 1000
                const handle = setTimeout(() => {
                    setRoundTableLiveCountdown(updatedTimeToLive - tickLength)
                }, tickLength)
                return () => {
                    clearTimeout(handle)
                }
            }
        }
    }, [roundTableLiveCountdown, roundTableLiveCountdownActive, eventDateTiming]) // eslint-disable-line
    // end timer

    const getCountdownText = (timeToLive: number, strings: Branding) => {
        const hoursToLive = timeToLive / (60 * 60 * 1000)
        const daysToLive = hoursToLive < 24 ? 0 : Math.round(hoursToLive / 24)
        const countdownText =
            hoursToLive < 1
                ? moment.utc(timeToLive).format("mm:ss")
                : hoursToLive < 24
                ? moment.utc(timeToLive).format("HH:mm:ss")
                : getPluralString(strings.eventDateDetailPageContent.eventDateRoundTableButtonCountdown, daysToLive, "days")
        return countdownText
    }

    /* #region Button Click Handlers */
    const onRequestAccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        if (!loggedInUser || !hasAccessToOrganization(loggedInUser, props.eventDate?.organizationId!)) {
            if (props.setShowGuestModal) props.setShowGuestModal(true)
            return
        }

        // user should not be able to send request for past events
        const now = getNow()
        if (now.isAfter(moment(props.eventDate.enddatetime))) {
            return
        }

        props.setShowRequestAccessModal(true)
    }

    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    const openShopLinkInNewTab = () => {
        let url = props.eventDate.ticketUrls && props.eventDate.ticketUrls.length ? props.eventDate.ticketUrls[0] : ""
        if (url?.length > 0) {
            if (!url.match(/^https?:\/\//i)) {
                url = "https://" + url
            }
            const newWindow = window.open(url, "_blank", "noopener,noreferrer")
            if (newWindow) newWindow.opener = null
        }
    }

    const onJoinMeetingButtonClicked = () => {
        chime.createOrJoinMeeting(props.eventDate.id, "roundtable")
    }
    /* #endregion */

    // configuration helper
    const calculateButtonConfig = (): void => {
        // some logical branches are redundant, but we are ok with that to make it easier to understand
        // when working with this code and being able to fine-tune further changes in the future more easily

        // this decision tree asks the following questions in the following order:

        // EventDateType={ROUNDTABLE, MASTERCLASS}
        // AccessProvider={(PUBLIC), BACKOFFICE, TICKET}
        // EventDateTiming={BEFORE, LIVE, AFTER}
        // isEventUnlocked={TRUE, FALSE}
        // accessStatus={REQUESTED, GRANTED} | ticketsUrlsAvailable={TRUE, FALSE}

        if (isRoundTable) {
            if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                if (eventDateTiming === EventDateTiming.BEFORE) {
                    if (isEventUnlocked) {
                        // branch 1: RT BACKOFFICE BEFORE UNLOCKED
                        disabled = true
                        if (props.theme === ActionButtonTheme.PRIVATE_EVENT_DARK_BOX) {
                            if (!roundTableLiveCountdownActive) setRoundTableLiveCountdownActive(true)
                            buttonText = getCountdownText(roundTableLiveCountdown, strings)
                            if (!props.isVisible) props.setIsVisible(true)
                        } else {
                            buttonText = "Join soon" // todo
                            if (props.isVisible) props.setIsVisible(false)
                        }
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 2: RT BACKOFFICE BEFORE LOCKED REQUESTED
                        buttonText = strings.globalTexts.requestSent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 3: RT BACKOFFICE BEFORE LOCKED NOT-REQUESTED
                        clickHandler = onRequestAccessClick
                        buttonText = strings.globalTexts.requestAccess
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.LIVE) {
                    if (isEventUnlocked) {
                        // branch 4: RT BACKOFFICE LIVE UNLOCKED
                        clickHandler = onJoinMeetingButtonClicked
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonAccessGrantedLive
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 5: RT BACKOFFICE LIVE LOCKED REQUESTED
                        buttonText = strings.globalTexts.requestSent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 6: RT BACKOFFICE LIVE LOCKED NOT-REQUESTED
                        clickHandler = onRequestAccessClick
                        buttonText = strings.globalTexts.requestAccess
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.AFTER) {
                    if (isEventUnlocked) {
                        // branch 7: RT BACKOFFICE AFTER UNLOCKED
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 8: RT BACKOFFICE AFTER LOCKED REQUESTED
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 9: RT BACKOFFICE AFTER LOCKED NOT-REQUESTED
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                }
            } else if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                if (eventDateTiming === EventDateTiming.BEFORE) {
                    if (isEventUnlocked) {
                        // branch 10: RT TICKET BEFORE UNLOCKED
                        disabled = true
                        if (props.theme === ActionButtonTheme.PRIVATE_EVENT_DARK_BOX) {
                            if (!roundTableLiveCountdownActive) setRoundTableLiveCountdownActive(true)
                            buttonText = getCountdownText(roundTableLiveCountdown, strings)
                            if (!props.isVisible) props.setIsVisible(true)
                        } else {
                            buttonText = "Join soon" // todo
                            if (props.isVisible) props.setIsVisible(false)
                        }
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 11: RT TICKET BEFORE LOCKED TICKETS-AVAILABLE
                        clickHandler = openShopLinkInNewTab
                        buttonText = strings.eventDateDetailPageContent.getTicketButtonText
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 12: RT TICKET BEFORE LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = strings.eventDateDetailPageContent.privateEventDateNoTicketsAvailable
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.LIVE) {
                    if (isEventUnlocked) {
                        // branch 13: RT TICKET LIVE UNLOCKED
                        clickHandler = onJoinMeetingButtonClicked
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonAccessGrantedLive
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 14: RT TICKET LIVE LOCKED TICKETSAVAILABLE
                        clickHandler = openShopLinkInNewTab
                        buttonText = strings.eventDateDetailPageContent.getTicketButtonText
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 15: RT TICKET LIVE LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = strings.eventDateDetailPageContent.privateEventDateNoTicketsAvailable
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.AFTER) {
                    if (isEventUnlocked) {
                        // branch 16: RT TICKET AFTER UNLOCKED
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 17: RT TICKET AFTER LOCKED TICKETSAVAILABLE
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 18: RT TICKET AFTER LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                }
            } else if (props.eventDate.accessProvider === AccessProvider.PUBLIC) {
                if (eventDateTiming === EventDateTiming.BEFORE) {
                    disabled = true
                    if (props.theme === ActionButtonTheme.PRIVATE_EVENT_DARK_BOX) {
                        // branch 19: RT PUBLIC BEFORE (UNLOCKED) BUTTON IN PRIVATE EVENT ACCESS VIEW
                        if (!roundTableLiveCountdownActive) setRoundTableLiveCountdownActive(true)
                        buttonText = getCountdownText(roundTableLiveCountdown, strings)
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 20: RT PUBLIC BEFORE (UNLOCKED) BUTTON ON PLAYER
                        buttonText = "Join soon" // todo
                        if (props.isVisible) props.setIsVisible(false)
                    }
                } else if (eventDateTiming === EventDateTiming.LIVE) {
                    // branch 21: RT PUBLIC LIVE (UNLOCKED)
                    clickHandler = onJoinMeetingButtonClicked
                    buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonAccessGrantedLive
                    disabled = false
                    if (!props.isVisible) props.setIsVisible(true)
                } else if (eventDateTiming === EventDateTiming.AFTER) {
                    // branch 22: RT PUBLIC AFTER (UNLOCKED)
                    buttonText = strings.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent
                    disabled = true
                    if (!props.isVisible) props.setIsVisible(true)
                }
            }
        } else {
            // if (props.eventDate.type === "masterclass")
            if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                if (eventDateTiming === EventDateTiming.BEFORE) {
                    if (isEventUnlocked) {
                        // branch 23: MC BACKOFFICE BEFORE UNLOCKED
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 24: MC BACKOFFICE BEFORE LOCKED REQUESTED
                        buttonText = strings.globalTexts.requestSent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 25: MC BACKOFFICE BEFORE LOCKED NOT-REQUESTED
                        clickHandler = onRequestAccessClick
                        buttonText = strings.globalTexts.requestAccess
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.LIVE) {
                    if (isEventUnlocked) {
                        // branch 26: MC BACKOFFICE LIVE UNLOCKED
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 27: MC BACKOFFICE LIVE LOCKED REQUESTED
                        buttonText = strings.globalTexts.requestSent
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 28: MC BACKOFFICE LIVE LOCKED NOT-REQUESTED
                        clickHandler = onRequestAccessClick
                        buttonText = strings.globalTexts.requestAccess
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.AFTER) {
                    if (isEventUnlocked) {
                        // branch 29: MC BACKOFFICE AFTER UNLOCKED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (accessStatus === RestrictedAreaAccessType.REQUESTED) {
                        // branch 30: MC BACKOFFICE AFTER LOCKED REQUESTED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else {
                        // branch 31: MC BACKOFFICE AFTER LOCKED NOT-REQUESTED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    }
                }
            } else if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                if (eventDateTiming === EventDateTiming.BEFORE) {
                    if (isEventUnlocked) {
                        // branch 32: MC TICKET BEFORE UNLOCKED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 33: MC TICKET BEFORE LOCKED TICKETSAVAILABLE
                        clickHandler = openShopLinkInNewTab
                        buttonText = strings.eventDateDetailPageContent.getTicketButtonText
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 34: MC TICKET BEFORE LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = strings.eventDateDetailPageContent.privateEventDateNoTicketsAvailable
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.LIVE) {
                    if (isEventUnlocked) {
                        // branch 35: MC TICKET LIVE UNLOCKED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 36: MC TICKET LIVE LOCKED TICKETSAVAILABLE
                        clickHandler = openShopLinkInNewTab
                        buttonText = strings.eventDateDetailPageContent.getTicketButtonText
                        disabled = false
                        if (!props.isVisible) props.setIsVisible(true)
                    } else {
                        // branch 37: MC TICKET LIVE LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = strings.eventDateDetailPageContent.privateEventDateNoTicketsAvailable
                        disabled = true
                        if (!props.isVisible) props.setIsVisible(true)
                    }
                } else if (eventDateTiming === EventDateTiming.AFTER) {
                    if (isEventUnlocked) {
                        // branch 38: MC TICKET AFTER UNLOCKED
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else if (props.eventDate.ticketUrls?.length > 0) {
                        // branch 39: MC TICKET AFTER LOCKED TICKETSAVAILABLE
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    } else {
                        // branch 40: MC TICKET AFTER LOCKED TICKETS-NOT-AVAILABLE
                        buttonText = ""
                        disabled = true
                        if (props.isVisible) props.setIsVisible(false)
                    }
                }
            } else if (props.eventDate.accessProvider === AccessProvider.PUBLIC) {
                // branch 41: MC PUBLIC
                buttonText = ""
                disabled = true
                if (props.isVisible) props.setIsVisible(false)
            }
        }
    }

    let clickHandler = undefined
    let buttonText: string = ""
    let disabled: boolean = false
    calculateButtonConfig()

    // request is running - we are displaying loader
    if (!userAccessState.isLoaded(props.eventDate)) {
        if (!props.isVisible) props.setIsVisible(true)
        return (
            <ActionButtonRoot className={className}>
                <CenteredLoader size="xs" />
            </ActionButtonRoot>
        )
    }
    // event is private, request is finished - request action button with proper text
    else {
        if (!props.isVisible) props.setIsVisible(true)
        return (
            <>
                <ActionButtonRoot className={className} onClick={clickHandler} disabled={disabled}>
                    <ActionButtonText>{buttonText}</ActionButtonText>
                </ActionButtonRoot>
            </>
        )
    }
}

interface ActionButtonConferencePageSubmitModalProps {
    meetingRoom: MeetingRoomGroupType
    setShowRequestAccessModal: (value: boolean) => void
    isVisible: boolean
    setIsVisible: (value: boolean) => void
}

const ActionButtonConferencePageSubmitModal: React.FC<ActionButtonConferencePageSubmitModalProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const accessStatus = userAccessState.getStatus(props.meetingRoom)
    const strings = useLanguageState().getStrings()
    const { groupId }: any = useParams()
    let button = null

    if (userAccessState.isUnlocked(props.meetingRoom)) {
        if (props.isVisible) props.setIsVisible(false)
    } else if (!userAccessState.isLoaded(props.meetingRoom)) {
        button = (
            <>
                <SubmitModalButton>
                    <CenteredLoader size="sm"></CenteredLoader>
                </SubmitModalButton>
            </>
        )
    } else {
        button = (
            <>
                <SubmitModalButton
                    style={{
                        pointerEvents: accessStatus === RestrictedAreaAccessType.UNAUTHORIZED ? "initial" : "none"
                    }}
                    onClick={() => props.setShowRequestAccessModal(true)}
                >
                    {accessStatus === RestrictedAreaAccessType.UNAUTHORIZED
                        ? strings.globalTexts.requestAccess
                        : strings.globalTexts.requestSent}
                </SubmitModalButton>
                <ConferenceRoomSubmitModalAccessMessage>
                    {strings.conferenceTexts.meetingsPageNoAccessMessage + groupId}
                </ConferenceRoomSubmitModalAccessMessage>
            </>
        )
    }
    return button
}

export default ActionButton
