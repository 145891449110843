import FileSaver from "file-saver"
import * as React from "react"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { trackVisit } from "../../../backendServices/TrackingServices"
import { Attachment } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser"
import { IconDownload, IconFile } from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/modals/PrivacyPolicyModal"
import { SimpleOrganization } from "../../ExhibitorsPageContent"
import { getContentType, MediaProps } from "./Media"
import { FormattedDateExtensionDiv, HeaderTitle } from "./StyledComponents"
import { device, MobileVersionContainer } from "../../../utils/Device"
import { useLanguageState } from "../../../globalStates/LanguageState"

const guessContentTypeExtension = (contentType: string) => {
    switch (contentType) {
        case "image/jpg":
            return ".jpg"
        case "image/jpeg":
            return ".jpeg"
        case "image/png":
            return ".png"
        case "application/pdf":
            return ".pdf"
        case "image/gif":
            return ".gif"
        case "image/bmp":
            return ".bmp"
        case "text/csv":
            return ".csv"
        case "application/postscript":
            return ".ai"
        case "image/tif":
            return ".tif"
        case "image/tiff":
            return ".tiff"
        case "text/html":
            return ".html"

        default:
            return ""
    }
}

const NonMediaGalleryRoot = styled.div<{ marginTop?: string }>`
    margin: 0;

    @media ${device.tablet} {
        margin-top: ${(props) => props.marginTop ?? "3rem"};
    }
`

const MediaRow = styled(Row)`
    display: flex;
    padding: 25px 0;
    justify-content: space-between;

    margin-left: 0px;
    margin-right: 0px;

    @media ${device.mobile} {
        margin-right: -5px !important;
    }
`

const DescriptionRow = styled(Row)`
    margin-bottom: 15px;
    margin-top: -10px;
    margin-left: 30px;

    @media ${device.mobile} {
        margin-left: 45px;
    }
`

const MediaImageCol = styled(Col)`
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    padding: 0;
`

const MediaColInfo = styled(Col)`
    padding-left: 25px;
    display: flex;
    align-items: center;
`

const MediaTitle = styled.p`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;

    @media ${device.mobile} {
        font-size: 12px;
    }
`

const MediaColIcons = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
`

const FormattedDateExtensionDivExtended = styled(FormattedDateExtensionDiv)`
    height: 20px;
    border: 1px solid ${branding.listDividerColor};
    padding: 0 5px;
    margin-left: 15px;
    cursor: pointer;
`

const ExstensionText = styled.p`
    font-family: ${branding.font1};
    font-size: 12px;
    margin: 0;
    padding: 0;
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface NonMediaGalleryProps {
    media: Attachment[]
    marginTop?: string
}

const NonGalleryMedia = (props: NonMediaGalleryProps) => {
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const user = useLoggedInState().user()
    const strings = useLanguageState().getStrings()

    const downloadClick = (
        props: React.PropsWithChildren<MediaProps>,
        showPrivacyPolicyModal: (organization: SimpleOrganization, callback: (accepted: boolean) => void) => void,
        att: Attachment,
        user: User | undefined
    ) => {
        if (props.organization)
            showPrivacyPolicyModal(props.organization, (accepted) => {
                startDownloadProcess(att, user!, props.organization)
            })
        else startDownloadProcess(att, user!, props.organization !== undefined ? props.organization : undefined)
    }

    const getFileNameWithExtension = (attachment: Attachment) => {
        const fileName = attachment.title
        const fileNameSplitted = fileName.split(".")
        const type = fileNameSplitted.length > 1 ? fileNameSplitted.pop() : null
        const extension = guessContentTypeExtension(attachment.contentType)
        return type ? fileName : `${fileName}${extension}`
    }

    const startDownloadProcess = (attachment: Attachment, loggedInUser: User, organization?: SimpleOrganization) => {
        if (attachment) {
            const fileName = getFileNameWithExtension(attachment)
            FileSaver.saveAs(attachment.url, fileName)
            if (organization?.id) trackVisit(loggedInUser.profileId, organization?.id, "MEDIA#DOWNLOAD", attachment.id)
        }
    }

    return (
        <NonMediaGalleryRoot marginTop={props.marginTop}>
            <PrivacyModal />
            {props.media.length > 0 && (
                <MobileVersionContainer>
                    <Row className="mb-2" style={{ marginRight: "5px" }}>
                        <HeaderTitle>
                            {props.media.length > 1
                                ? strings.trademarkDetailPageContent.downloadsTitle + " (" + props.media.length + ")"
                                : branding.trademarkDetailPageContent.downloadTitle + " (" + 1 + ")"}
                        </HeaderTitle>
                    </Row>
                </MobileVersionContainer>
            )}
            {props.media.map((att, key) => {
                const exstension = getContentType(att)
                return (
                    <div key={key} style={{ borderBottom: "1px solid " + branding.listDividerColor }}>
                        <MediaRow>
                            <MediaImageCol>
                                {IconFile({ width: "20px", height: "20px", fill: branding.sideIconBar.sideIconColorDark })}
                            </MediaImageCol>
                            <MediaColInfo onClick={() => downloadClick(props, showPrivacyPolicyModal, att, user)}>
                                <MediaTitle>{att.title ? att.title : "NO TITLE"}</MediaTitle>
                                {exstension && exstension.length < 10 && (
                                    <FormattedDateExtensionDivExtended>
                                        <ExstensionText>{exstension}</ExstensionText>
                                    </FormattedDateExtensionDivExtended>
                                )}
                            </MediaColInfo>
                            <MediaColIcons>
                                <DownloadIconDiv
                                    onClick={() => {
                                        downloadClick(props, showPrivacyPolicyModal, att, user)
                                    }}
                                >
                                    {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                                </DownloadIconDiv>
                            </MediaColIcons>
                        </MediaRow>
                        {att.description && (
                            <DescriptionRow>
                                <ExstensionText>{att.description}</ExstensionText>
                            </DescriptionRow>
                        )}
                    </div>
                )
            })}
        </NonMediaGalleryRoot>
    )
}

export default NonGalleryMedia
