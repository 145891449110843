import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import { IconLock, IconTicket, VGIcon } from "./Icons"
import { DetailNavLink, buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { NavLink } from "react-router-dom"
import { useUserRestrictedAreaAccess } from "../globalStates/UserRestrictedAreaAccess"
import { useLanguageState } from "../globalStates/LanguageState"
import { AccessProvider, EventDateBase } from "../backendServices/Types"
import ActionButton, { ActionButtonTheme } from "../contentArea/videoPlayer/ActionButton"
import { UserOrganizationVisitSource } from "../backendServices/TrackingServices"

const LiveTileRoot = styled(NavLink)<{ unlocked?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 280px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    overflow: hidden;
    ${(props) => (props.unlocked ? "" : "background-color: grey")};

    &:after {
        border-radius: 5px;
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
        text-decoration: none;
    }

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 10px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
`

const MobileLiveTileRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 280px;
    margin: ${branding.liveTileMargins ?? "0px 10px 10px 0px"};
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    overflow: hidden;

    &:after {
        border-radius: 5px;
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
        text-decoration: none;
    }

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        display: flex;
        margin-left: 5px;
        margin-top: 3px;
        flex: 1;
        text-align: center;
    }
    &:before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 5px;
    }

    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
`

const LiveTileHeader = styled.div`
    position: absolute;
    display: flex;
    flex-flow: row;
    padding: 15px;
    width: 100%;
    color: white;
    z-index: 2;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    background-blend-mode: multiply;
`

const MobileLiveTileHeader = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 15px;
    padding-left: 5px;
    padding-top: 5px;
    overflow-y: hidden;
    width: 100%;
    color: white;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
`

const LiveTileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 480px;
    font-family: ${branding.font1};
`

const MobileLiveTileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 120px;
    margin-bottom: -20px;
`

const LockedEvent = styled.div`
    width: "480px";
    min-width: "480px";
    height: "270px";
    cursor: "pointer";
    border-radius: "5px";
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
`

const LockedText = styled.div`
    color: #fff !important;
    margin-top: 20px;
    text-align: center;

    &:active,
    &:link,
    &:focus {
        color: #fff !important;
    }
`

const LiveTileTextWrapper = styled.div`
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

interface LiveEventDateProps {
    id: string
    title: string
    channelId: string
    channelIcon: VGIcon
    thumbnailUrl: string
    isMobile?: boolean
    eventDate?: EventDateBase
    setShowGuestModal?: (value: boolean) => void
    rerenderOnUnlockedChanged?: boolean
    src: UserOrganizationVisitSource
    // pass userAccessState.isUnlocked() here to update component when access is changed in backoffice
    // we don't actually do anything with this property here
}

const LiveEvent: React.FunctionComponent<LiveEventDateProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const strings = useLanguageState().getStrings()
    const unlocked = userAccessState.isUnlocked(props.eventDate)
    const displayTicketIcon = props.eventDate?.accessProvider === AccessProvider.TICKET

    const lang = useLanguageState().getLanguage()

    if (props.isMobile) {
        return (
            <MobileLiveTileWrapper>
                <MobileLiveTileRoot to={buildDetailLink(props.channelId, props.title, "channel")} style={{ height: "95px" }}>
                    <DetailNavLink type="channel" id={props.channelId} name={props.title} source={props.src}>
                        <MobileLiveTileHeader style={{ fontSize: "10px", lineHeight: "17px" }}>
                            <div>{props.channelIcon}</div>
                            <h2>{props.title}</h2>
                        </MobileLiveTileHeader>
                        <img
                            src={props.thumbnailUrl}
                            style={{ width: "135px", minWidth: "135px", height: "95px", cursor: "pointer", borderRadius: "5px" }}
                            alt=""
                        />
                    </DetailNavLink>
                </MobileLiveTileRoot>
            </MobileLiveTileWrapper>
        )
    }

    let content = null

    if (unlocked || props.setShowGuestModal === undefined) {
        content = (
            <img
                src={props.thumbnailUrl}
                style={{ width: "480px", minWidth: "480px", height: "280px", cursor: "pointer", borderRadius: "5px" }}
                alt=""
            />
        )
    } else {
        content = (
            <LockedEvent>
                {displayTicketIcon
                    ? IconTicket({ width: "50", height: "50", fill: "#fff" })
                    : IconLock({ width: "50", height: "50", fill: "#fff" })}
                {displayTicketIcon ? (
                    <LockedText>{strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessageTicket}</LockedText>
                ) : (
                    <LockedText>{strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessageBackoffice}</LockedText>
                )}
            </LockedEvent>
        )
    }

    return (
        <LiveTileWrapper>
            {props.eventDate && (
                <ActionButton
                    restrictedArea={props.eventDate}
                    setShowGuestModal={props.setShowGuestModal}
                    theme={ActionButtonTheme.PRIVATE_EVENT_LIGHT_PLAYER}
                ></ActionButton>
            )}
            <LiveTileRoot to={"#"} unlocked={unlocked}>
                <DetailNavLink type={"channel"} id={props.channelId} name={props.title} disabled={false} source={props.src}>
                    <LiveTileHeader>
                        <div>{props.channelIcon}</div>
                        <h2>{props.title}</h2>
                    </LiveTileHeader>
                    {content}
                </DetailNavLink>
            </LiveTileRoot>
            <LiveTileTextWrapper>
                {lang === "de" ? props.eventDate?.nameDe : props.eventDate?.name || <div style={{ height: "10px" }}></div>}
            </LiveTileTextWrapper>
        </LiveTileWrapper>
    )
}

export default LiveEvent
