import * as React from "react"
import VideoPlayerDummy from "./VideoPlayerDummy"
import VideoPlayerReal from "./VideoPlayerReal"
import { EventDate } from "../../backendServices/Types"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"

interface VideoPlayerProps {
    streamUrl: string
    live: boolean
    eventDate?: EventDate
    disablePlaying?: boolean
    setShowGuestModal(value: boolean): void
    onPlay?: (timeStamp: number) => void
    onPause?: (timeStamp: number) => void
    onSeeking?: () => void
    onSeeked?: (timeStamp: number) => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    return (
        <div>
            {
                // if event is not private or user has access,
                // we can display real Video Player
                // otherwise, we will display dummy Video Player

                userAccessState.isUnlocked(props.eventDate) ? (
                    <VideoPlayerReal
                        onPlay={(ts) => props.onPlay && props.onPlay(ts)}
                        onPause={(ts) => props.onPause && props.onPause(ts)}
                        onSeeking={() => props.onSeeking && props.onSeeking()}
                        onSeeked={(ts) => props.onSeeked && props.onSeeked(ts)}
                        src={props.streamUrl}
                        controls={true}
                        live={props.live}
                        disablePlaying={props.disablePlaying}
                        rerenderOnUnlockedChanged={userAccessState.isUnlocked(props.eventDate)}
                        posterImage={props.eventDate?.liveStreamingPreviewUrl}
                    />
                ) : (
                    props.eventDate && (
                        <VideoPlayerDummy eventDate={props.eventDate} setShowGuestModal={props.setShowGuestModal} />
                    )
                )
            }
        </div>
    )
}

export default VideoPlayer
