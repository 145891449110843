import styled from "styled-components"
import { useEffect, useState } from "react"
import * as React from "react"
import CenteredLoader from "../ui/CenteredLoader"
import { useLanguageState } from "../globalStates/LanguageState"
import TopBar from "../navigationArea/TopBar"
import EmptyTile from "./reception/EmptyTile"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import GuestUserBanner from "./guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "./guestUserBanner/GuestUserBannerSharedState"
import SayHelloModal from "../ui/modals/SayHelloModal"
import { EntityType, Suggestion } from "../backendServices/Types"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/modals/CalendarEntryModal"
import { useSearchContext } from "./useSearchContext"
import { ResultListLayout } from "../ui/ResultListLayout"
import branding, { Branding } from "../branding/branding"
import GuestModal from "../ui/modals/GuestModal"
import PrivacyPolicyModal from "../ui/modals/PrivacyPolicyModal"
import { joinShowroom } from "./detailPages/OrganizationDetailPageContent"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { ActionType } from "./entitiesActions/actionsUtils"
import { SimpleOrganization } from "./ExhibitorsPageContent"
import { OptionTypeBase, ValueType, ActionMeta, components } from "react-select"
import { SelectThemeCustom } from "./myprofile/MobileEditMyProfileLayout"
import { IconReset } from "../ui/Icons"
import { StyledSelect } from "./GlobalStyledComponents"
import { useAppState } from "../globalStates/AppState"
import { OnlyBookmarksButton } from "./BusinessAreaPageContent"
import { device, MobileVersionContainer } from "../utils/Device"
import TopBannerSharedState from "./advertisingBanner/TopBannerSharedState"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { loadExhibitorData } from "../backendServices/ExhibitorServices"
import InView from "react-intersection-observer"
import { CategoryImg } from "../ui/BadgeArea"

const getEntityTitle = (entityType: EntityType, strings: Branding): string => {
    return strings.globalSearchResultPage.searchEntities.filter((item) => item.entityType === entityType)[0]?.title
}

/* #region  Dropdown Custom Components Section  */
const CustomOptionRoot = styled(components.Option)`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${branding.globalSearchResultPage.dropdownOptionColor ?? "#202428"} !important;
    background-color: transparent !important;
    font-family: ${branding.font1};
    padding: 10px 15px !important;

    &:hover {
        background-color: #ccc !important;
        cursor: pointer;
    }
`
export const CheckboxParent = styled.div`
    display: flex;
    align-items: center;
    font: ${branding.font1};
    margin-right: 20px;
`

const CheckboxInput = styled.input`
    min-width: 12px;
    width: 12px;
    height: 12px;
    background: #fff;
    border: 1px solid #000;
`

export const Checkbox = (props: any) => <CheckboxInput type="checkbox" {...props} />

export const CustomSelectOption = ({ children, ...props }: any) => {
    const emptyCheckBoxes = props.selectProps.emptyCheckBoxes
    return (
        <CustomOptionRoot {...props}>
            <div className="d-flex">
                <CheckboxParent style={{ marginRight: props.data.iconSmallUrl ? "10px" : "20px" }}>
                    <Checkbox checked={props.isSelected && !emptyCheckBoxes} />
                </CheckboxParent>
                {props.data.iconSmallUrl && (
                    <div style={{ display: "flex", justifyContent: "center", width: "40px" }}>
                        <CategoryImg
                            style={{ marginTop: "1px", marginRight: "5px" }}
                            key={props.data.id + props.data.badgeName + "img"}
                            src={props.data.iconSmallUrl}
                        />
                    </div>
                )}
                {children}
            </div>
        </CustomOptionRoot>
    )
}

const CustomPlaceholderRoot = styled(components.Placeholder)`
    font-family: ${branding.font1};

    & .placeholder-default {
        display: flex;
        align-items: center;
        color: ${branding.globalSearchResultPage.dropdownPlaceholderDefaultColor ?? "#727272"};
    }

    & .placeholder-custom {
        display: flex;
        align-items: center;
        color: ${branding.globalSearchResultPage.dropdownPlaceholderCustomColor ?? "#202428"};
    }
`

export const CustomSelectPlaceholder = ({ children, ...props }: any) => {
    const selectedValues = props.getValue()
    const count = selectedValues.length
    const firstEntitySelectedLabel = selectedValues[0]?.label
    const placeholderDefault = props.selectProps.placeholder
    const placeholderCustom = `+${count - 1}`
    const emptyCheckBoxes = props.selectProps.emptyCheckBoxes
    return (
        <CustomPlaceholderRoot {...props}>
            {emptyCheckBoxes || !count || count === 0 ? (
                <div className="placeholder-default">{placeholderDefault}</div>
            ) : (
                <div className="placeholder-custom">
                    <div className="mr-2">{firstEntitySelectedLabel}</div>
                    {count > 1 && <div>{placeholderCustom}</div>}
                </div>
            )}
        </CustomPlaceholderRoot>
    )
}
/* #endregion */

const FilterSectionWrapper = styled.div<{ showShadow?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    position: relative;
    z-index: 10;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};

    & .dropdown-resetBtn-wrapper {
        height: 35px;
    }

    & [class*="-control"],
    [class*="-IndicatorsContainer"] {
        height: 100%;
        min-height: unset !important;
    }

    & [class*="-control"] {
        padding-left: 15px;
    }

    & [class*="-ValueContainer"],
    [class*="-placeholder"] {
        padding: 0;
        margin: 0;
    }

    & [class*="-menu"],
    [class*="-control"]:hover {
        box-shadow: unset !important;
        border: 1px solid ${branding.globalSearchResultPage.dropdownBorderColor ?? "#727272"};
    }

    & .svg-icons-wrapper {
        span,
        svg {
            pointer-events: none;
        }
    }

    @media ${device.mobile} {
        padding-left: 15px;
        padding-right: 15px;
    }
`

const ResetBtnRoot = styled.button`
    display: flex;
    font-family: ${branding.font1};
    height: 35px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: ${branding.globalSearchResultPage.resetBtnBgColor ?? "#6DD400"};
    color: ${branding.globalSearchResultPage.resetBtnColor ?? "#FFF"};
    font-size: 12px;
    cursor: pointer;
    user-select: none;

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`

const ResetBtnTitle = styled.span`
    position: relative;
    text-align: center;
    padding: 0.3rem 1rem;
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ContentRoot = styled.div<{ guestBannerHeight: number; additionalHeight: number }>`
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    display: flex;
    flex-direction: column;
    height: ${(props) => `calc(100vh - ${props.guestBannerHeight}px - ${props.additionalHeight}px)`};
    overflow-x: hidden;

    & .ScrollbarsCustom-Scroller {
        overflow-x: hidden !important;
        padding-bottom: 20px;
    }

    & .ScrollbarsCustom-Content {
        overflow: hidden;
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }

    & .svg-icons-wrapper {
        span,
        svg {
            pointer-events: none;
        }
    }
`

const ContainerRoot = styled.div``

const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
const ResultsContainer = styled.div`
    height: calc(100vh - 40px);
    overflow: hidden;
`

const GlobalSearchResultPage: React.FunctionComponent = (props) => {
    // GLOBAL STATES
    const userLink = useLoggedInState()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()
    const appState = useAppState()
    const suggestState = useSuggestContext()

    // BANNERS
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()

    // MODALS
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState<boolean>(false)
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState<boolean>(false)
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState<boolean>(false)
    const [target, setTarget] = useState<SimpleOrganization | null>()
    const [action, setAction] = useState<ActionType | undefined>()

    // SEARCH
    const { searchParams, results, isLoading, searchFunctions } = useSearchContext()
    const entityFilterOptions = strings.globalSearchResultPage.searchEntities.map((entity) => {
        return { label: entity.title, value: entity.entityType }
    })
    const [selectedFilters, setSelectedFilters] = useState<(OptionTypeBase | null)[]>(
        searchParams.entityTypes.map((entity) => {
            return { label: getEntityTitle(entity, strings), value: entity }
        })
    )

    // OTHER
    const { hideOnScroll, setHideOnScroll } = TopBannerSharedState()

    useEffect(() => {
        searchFunctions.init(false)
        appState.setCurrentMobileBreadcrumb(strings.globalSearchResultPage.pageTitle)

        if (localStorage.getItem("tempSuggestion")) {
            let suggestion: Suggestion = JSON.parse(localStorage.getItem("tempSuggestion") || "")
            suggestState.addSuggestion(suggestion)

            localStorage.removeItem("tempSuggestion")
        }
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!searchParams.entityTypes || searchParams.entityTypes.length === 0) setSelectedFilters([])
        else {
            const entityTypesTemp = searchParams.entityTypes.map((entity) => {
                return { label: getEntityTitle(entity, strings), value: entity }
            })
            setSelectedFilters(entityTypesTemp)
        }
    }, [searchParams.entityTypes]) // eslint-disable-line

    // todo: this needs to be removed when Privacy- and GuestModal are refactored here
    useEffect(() => {
        setTarget(null)
    }, []) // eslint-disable-line

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    const policyCallback = (accepted: boolean) => {
        switch (action) {
            case "MEETING":
                setShowRequestMeetingModal(true)
                break
            case "INTEREST":
                setShowRequestOrganizationModal(true)
                break
            case "EXPO":
                loadExhibitorData({
                    organizationid: target?.id!,
                    hideNewsdata: true
                }).then((resp) => {
                    joinShowroom(resp.content, userLink.user()!, chime, meeting)
                })
                break
        }
        setAction(undefined)
        setShowPrivacyPolicyModal(false)
    }

    let content: JSX.Element
    let totalCount = 0
    Object.values(results).forEach((result) => {
        if (result) totalCount += result.count
    })

    if (searchParams.page === 0 && isLoading) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    } else {
        const hasMoreData = searchFunctions.hasMoreData()

        content = (
            <ContentRoot
                guestBannerHeight={guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0}
                additionalHeight={150}
            >
                <ContentScrollContainer handleScroll={onScroll}>
                    {totalCount === 0 && !hasMoreData ? (
                        <div style={{ marginTop: "10%" }}>
                            <EmptyTile header={strings.sideIconBar.emptyResultMessage} bgColor="transparent" hideButton={true} />
                        </div>
                    ) : (
                        <>
                            <ResultListLayout
                                sections={results}
                                isSponsor={false}
                                isMediaPartner={false}
                                isStartup={false}
                                isResultPage={true}
                                setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
                                setShowGuestModal={setShowGuestModal}
                            />
                            {hasMoreData && (
                                <InView
                                    threshold={0.1}
                                    onChange={(inView) => {
                                        if (inView) searchFunctions.nextPage()
                                    }}
                                >
                                    <NextPageLoader />
                                </InView>
                            )}
                        </>
                    )}
                </ContentScrollContainer>
            </ContentRoot>
        )
    }

    return (
        <>
            <div style={{ background: "#fff" }}>
                <GuestUserBanner setRef={setGuestUserBannerRef} />
                <TopBar />

                {/* FILTER SECTION */}
                <FilterSectionWrapper showShadow={hideOnScroll}>
                    <div className="dropdown-resetBtn-wrapper d-flex w-100">
                        {/* DROPDOWN */}
                        <StyledSelect
                            components={{
                                Option: CustomSelectOption,
                                Placeholder: CustomSelectPlaceholder
                            }}
                            placeholder={strings.globalSearchResultPage.dropdownPlaceholder}
                            isMulti={true}
                            isSearchable={false}
                            isClearable={true}
                            options={entityFilterOptions}
                            value={selectedFilters}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            closeMenuOnSelect={false}
                            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                if (value !== null) {
                                    const newFilters = value.map((x: any) => {
                                        return (x as OptionTypeBase).value
                                    })
                                    setSelectedFilters(value as any)
                                    searchFunctions.setEntityFilter(newFilters)
                                }
                            }}
                            theme={SelectThemeCustom}
                            emptyCheckBoxes={searchParams.emptyCheckBoxes ?? false}
                        />

                        {/* RESET BUTTON */}
                        <ResetBtnRoot
                            onClick={() => {
                                searchFunctions.reset()
                                setSelectedFilters([])
                            }}
                            disabled={searchParams.emptyCheckBoxes}
                        >
                            <span>{IconReset({ fill: branding.sideIconBar.sideIconColorLight })}</span>
                            <ResetBtnTitle>{strings.globalSearchResultPage.resetBtnTitle}</ResetBtnTitle>
                        </ResetBtnRoot>

                        <MobileVersionContainer>
                            <div style={{ marginLeft: "10px" }}>
                                <OnlyBookmarksButton
                                    bookmarkFilter={searchParams.showOnlyBookmarks}
                                    updateBookmarkFilter={() => searchFunctions.setBookmarksOnly(!searchParams.showOnlyBookmarks)}
                                />
                            </div>
                        </MobileVersionContainer>
                    </div>
                </FilterSectionWrapper>

                {/* RESULTS */}
                <ResultsContainer>
                    <ContainerRoot>{content}</ContainerRoot>
                </ResultsContainer>
            </div>
            {showRequestOrganizationModal && (
                <SayHelloModal
                    organizationModal={true}
                    targetId={target?.id!}
                    handleClose={() => setShowRequestOrganizationModal(false)}
                ></SayHelloModal>
            )}
            {showRequestMeetingModal && (
                <CalendarEntryModal
                    viewMode={CalendarEntryModalViewMode.CREATE}
                    organizationId={target?.id!}
                    sotUser={[]}
                    close={() => setShowRequestMeetingModal(false)}
                />
            )}
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
            {showPrivacyPolicyModal && <PrivacyPolicyModal callback={policyCallback} organizationId={target?.id!} />}
        </>
    )
}

export default GlobalSearchResultPage
