import * as React from "react"
import styled from "styled-components"
import { AccessProvider, EventDate } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import CenteredLoader from "../../ui/CenteredLoader"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { IconLock, IconTicket } from "../../ui/Icons"
import ActionButton, { ActionButtonTheme } from "./ActionButton"

const VideoPlayerDummyRoot = styled.div<{ isLoaded: boolean }>`
    position: relative;
    // loading player is black so it doesn't flash grey for half a second when access is granted
    // and normal player should be displayed
    background-color: ${(props) => (props.isLoaded ? "#c9c9c9" : "black")};

    #virtualGuide & .dummy-player-center-content-and-keep-aspect-ratio {
        // keep aspect ratio of 9:16 to mimic real video player
        height: 0;
        padding-top: 56.25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const LoaderParent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
`

const LockParent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`

const LockIconParent = styled.div`
    width: 10vw;
    height: 10vw;
`

const NoAccessMessageRoot = styled.div`
    width: 80%;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
`

interface VideoPlayerDummyProps {
    eventDate: EventDate
    setShowGuestModal(value: boolean): void
}

const VideoPlayerDummy: React.FC<VideoPlayerDummyProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const userAccessState = useUserRestrictedAreaAccess()
    const displayTicketIcon = props.eventDate.accessProvider === AccessProvider.TICKET

    let content = null

    if (!userAccessState.isLoaded(props.eventDate))
        content = (
            <LoaderParent>
                <CenteredLoader />
            </LoaderParent>
        )
    else
        content = (
            <LockParent>
                <LockIconParent>
                    {displayTicketIcon
                        ? IconTicket({ width: "auto", height: "auto" })
                        : IconLock({ width: "auto", height: "auto" })}
                </LockIconParent>
                {displayTicketIcon ? (
                    <NoAccessMessageRoot>{strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessageTicket}</NoAccessMessageRoot>
                ) : (
                    <NoAccessMessageRoot>
                        {strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessageBackoffice}
                    </NoAccessMessageRoot>
                )}
            </LockParent>
        )

    return (
        <div>
            <VideoPlayerDummyRoot isLoaded={userAccessState.isLoaded(props.eventDate)}>
                <ActionButton
                    restrictedArea={props.eventDate}
                    setShowGuestModal={props.setShowGuestModal}
                    theme={ActionButtonTheme.PRIVATE_EVENT_LIGHT_PLAYER}
                />
                <div className="dummy-player-center-content-and-keep-aspect-ratio">{content}</div>
            </VideoPlayerDummyRoot>
        </div>
    )
}

export default VideoPlayerDummy
