import { Fragment, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Category, EntityType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useAppState } from "../../../globalStates/AppState"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { globalSearchResultPageRoute } from "../../../navigationArea/RoutePaths"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import TileRow from "../../../ui/TileRow"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../../utils/Device"
import { ViewAllButton } from "./DetailPageSections"
import SimpleTreeComponent, { TreeData } from "./SimpleTreeComponent"
import { HeaderTitle } from "./StyledComponents"

interface CategoriesProps {
    categories: Category[]
    categoryType: EntityType
    viewAllInitially?: boolean
    className?: string
}

const CategoryItem = styled.div<{
    backgroundColor: string
    textColor: string
    backgroundActiveColor: string
    textActiveColor: string
}>`
    display: inline-block;
    padding: 8px 15px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    font-size: 14px;
    font-weight: 700;

    margin: 5px;
    margin-left: 0;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;

    &.active {
        background-color: ${(props) => props.backgroundActiveColor};
        color: ${(props) => props.textActiveColor};
    }
`

interface CategoryGroupProps {
    category: Category
}

const CategoryGroupRow = styled(Row)`
    margin-bottom: 1.2rem !important;
    margin-left: 5px;
    margin-right: 5px;

    @media ${device.tablet} {
        margin-bottom: 3rem !important;
    }
`

const CategoryGroup = (props: CategoryGroupProps) => {
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

    const suggestState = useSuggestContext()
    const history = useHistory()

    const onCategorySelect = (category: Category) => {
        if (selectedCategory?.key === category.key) {
            setSelectedCategory(null)
        } else {
            if (category.nodes?.length) {
                setSelectedCategory(category)
            } else {
                setSelectedCategory(null)
                if (category.key && category.label) {
                    suggestState.addSuggestion({
                        icon: "",
                        id: "",
                        subtitle: "",
                        title: category.label,
                        value: "cat_" + category.key,
                        active: true
                    })
                    history.push(globalSearchResultPageRoute)
                }
            }
        }
    }

    return (
        <CategoryGroupRow className="mt-5">
            <div style={{ width: "100%" }}>
                <HeaderTitle className="mb-3">{props.category.label}</HeaderTitle>
                {sortCategories(props.category.nodes || [])?.map((category, index) => {
                    return (
                        <Fragment key={index}>
                            <CategoryItem
                                className={category.key === selectedCategory?.key ? "active" : ""}
                                backgroundColor={branding.organizationDetailPageContent.categoryFillColor}
                                backgroundActiveColor={branding.organizationDetailPageContent.categoryActiveFillColor}
                                textColor={branding.organizationDetailPageContent.categoryTextColor}
                                textActiveColor={branding.organizationDetailPageContent.categoryActiveTextColor}
                                onClick={() => onCategorySelect(category)}
                            >
                                {category.label}
                            </CategoryItem>
                        </Fragment>
                    )
                })}
                {selectedCategory && selectedCategory.nodes && selectedCategory.nodes.length > 0 && (
                    <SimpleTreeComponent
                        treeData={selectedCategory.nodes!.map((node) => {
                            return {
                                key: node.key!,
                                label: node.label!,
                                nodes: node.nodes as unknown as TreeData[]
                            }
                        })}
                    />
                )}
            </div>
        </CategoryGroupRow>
    )
}

const CategoriesRow = styled(Row)`
    padding-bottom: 2rem !important;

    @media ${device.tablet} {
        padding-bottom: 3rem !important;
    }
`

const CategoriesContainer = styled.div`
    margin-left: 25px;

    @media ${device.tablet} {
        margin-left: 15px;
        margin-right: 0;
        width: calc(100% - 30px);

        &.evtdDetail {
            margin-left: 5px;
            margin-right: 0;
            width: calc(100% - 10px);
        }
    }
`

const MobileCategoryItem = styled.div<{ bold?: Boolean; borderColor?: string; textColor?: string }>`
    display: inline-block;
    padding: 0.5rem;
    padding-top: 0.3rem;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.textColor ?? branding.mainInfoColor};
    border: 1px solid ${(props) => props.borderColor ?? branding.mainInfoColor};
    font-weight: normal;
    text-transform: unset;
    margin: 0.3rem;
    border-radius: 5px;

    font-family: ${(props) => (props.bold ? branding.font2 : branding.font1)};
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    text-transform: ${(props) => (props.bold ? "uppercase" : "unset")};

    &.active {
        color: #fff;
        border: 1px solid ${(props) => branding.crsTabs.defaultActionItemActiveStateColor};
        background: ${(props) => branding.crsTabs.defaultActionItemActiveStateColor};
    }
`

export function sortCategories(categories: Category[]) {
    let categoriesToSort: Category[] = []

    categories.forEach((category: Category) => {
        categoriesToSort.push(Object.assign({}, category))
    })

    categoriesToSort = categoriesToSort.sort((a, b) => (a.orderkey.toLowerCase() > b.orderkey.toLowerCase() ? 1 : -1))

    return categoriesToSort
}

const Categories = (props: CategoriesProps) => {
    const strings = useLanguageState().getStrings()

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

    const suggestState = useSuggestContext()
    const history = useHistory()

    const [viewAll, setViewAll] = useState<boolean>(
        props.viewAllInitially ?? (props.categories && props.categories.length <= 3) ?? false
    )

    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const appState = useAppState()
    const showCode = branding.categoriesPageContent.categoriesBadgeShowCode

    useEffect(() => {
        props.categories.forEach((cat) => {
            if (cat.key === selectedCategory?.key) {
                setSelectedCategory(cat)
            }
        })
    }, [props.categories, selectedCategory])

    const onCategorySelect = (category: Category) => {
        if (selectedCategory?.key === category.key) {
            setSelectedCategory(null)
        } else {
            if (category.nodes?.length) {
                setSelectedCategory(category)
            } else {
                setSelectedCategory(null)
                if (category.key && category.label) {
                    suggestState.addSuggestion({
                        icon: "",
                        id: "",
                        subtitle: "",
                        title: generateCategoryLabel(category),
                        value: "cat_" + category.key,
                        active: true
                    })
                    history.push(globalSearchResultPageRoute)
                }
            }
        }
    }

    let rootCategories: string[] = []

    const mapNodes = (nodes: Category[] | undefined): TreeData[] => {
        return nodes?.map((node) => {
            return { key: node.key, label: generateCategoryLabel(node), nodes: mapNodes(sortCategories(node.nodes || [])) }
        }) as unknown as TreeData[]
    }

    const generateCategoryLabel: any = (category: Category) => {
        if (appState.currentItem === "program") {
            if (language === "en") {
                if (showCode && category.code) {
                    return category.code + " " + category.name
                } else {
                    return category.name
                }
            } else {
                if (showCode && category.code) {
                    return category.code + " " + category.nameDe ?? category.name
                } else {
                    return category.nameDe ?? category.name
                }
            }
        } else {
            if (showCode && category.code) {
                return category.code + " " + category.label
            } else {
                return category.label
            }
        }
    }

    switch (props.categoryType) {
        case "organization":
            rootCategories = branding.organizationDetailPageContent.rootCategories
            break
        case "product":
            rootCategories = branding.productDetailPageContent.rootCategories
            break
        case "trademark":
            rootCategories = branding.trademarkDetailPageContent.rootCategories
            break
        case "eventdate":
            rootCategories = branding.eventDateDetailPageContent.rootCategories
            break
        case "news":
            rootCategories = branding.newsPageContent.rootCategories
            break
        case "joboffer":
            rootCategories = branding.jobofferDetailPageContent.rootCategories
            break
    }

    if (rootCategories.length) {
        return (
            <>
                {props.categories.map((category, index) => {
                    return <CategoryGroup key={index} category={category} />
                })}
            </>
        )
    } else {
        return (
            <CategoriesRow className="mt-1">
                <Col xs={12} className="pr-0 pl-0">
                    <CategoriesContainer className={props.className ?? ""}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "inline-block" }}>
                                <HeaderTitle className="mb-3">
                                    {" "}
                                    {strings.organizationDetailPageContent.categoriesSectionTitle}
                                </HeaderTitle>
                            </div>
                            {props.categories && props.categories?.length! > 3 && (
                                <div style={{ marginRight: "27px" }}>
                                    <ViewAllButton viewAll={viewAll} setViewAll={setViewAll} />
                                </div>
                            )}
                        </div>
                        <DesktopVersionContainer>
                            {sortCategories(props.categories).map((category, index) => {
                                return (
                                    <CategoryItem
                                        key={index}
                                        className={category.key === selectedCategory?.key ? "active" : ""}
                                        backgroundColor={branding.organizationDetailPageContent.categoryFillColor}
                                        backgroundActiveColor={branding.organizationDetailPageContent.categoryActiveFillColor}
                                        textColor={branding.organizationDetailPageContent.categoryTextColor}
                                        textActiveColor={branding.organizationDetailPageContent.categoryActiveTextColor}
                                        onClick={() => onCategorySelect(category)}
                                    >
                                        {generateCategoryLabel(category)}
                                    </CategoryItem>
                                )
                            })}
                        </DesktopVersionContainer>
                        <MobileVersionContainer style={{ width: "93%" }}>
                            {" "}
                            {/** temporary fix */}
                            {viewAll ? (
                                <div>
                                    {sortCategories(props.categories).map((category, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <MobileCategoryItem
                                                    key={category.id}
                                                    onClick={() => onCategorySelect(category)}
                                                    className={category.key === selectedCategory?.key ? "active" : ""}
                                                >
                                                    {generateCategoryLabel(category)}
                                                </MobileCategoryItem>
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            ) : (
                                <TileRow
                                    key="categories"
                                    iconVisible={true}
                                    title={strings.organizationDetailPageContent.categoriesSectionTitle}
                                    titleVisible={false}
                                    navLinkTextVisible={true}
                                    hideShadows={true}
                                    navLinkText={strings.receptionPage.participantsNavLinkText}
                                    scrollBar={props.categories && props.categories?.length > 0}
                                    childWidth={95 + 9}
                                    childCount={props.categories?.length ?? 0}
                                    customMargin="-45px 0px 0 0px"
                                >
                                    {sortCategories(props.categories).map((category, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <MobileCategoryItem
                                                    onClick={() => onCategorySelect(category)}
                                                    className={category.key === selectedCategory?.key ? "active" : ""}
                                                >
                                                    {generateCategoryLabel(category)}
                                                </MobileCategoryItem>
                                            </Fragment>
                                        )
                                    })}
                                </TileRow>
                            )}
                        </MobileVersionContainer>
                        {selectedCategory && (
                            <SimpleTreeComponent
                                treeData={selectedCategory.nodes!.map((node) => {
                                    return {
                                        key: node.key!,
                                        label: generateCategoryLabel(node),
                                        nodes: mapNodes(sortCategories(node.nodes || []))
                                    }
                                })}
                            />
                        )}
                    </CategoriesContainer>
                </Col>
            </CategoriesRow>
        )
    }
}

export default Categories
