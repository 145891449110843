import { useState, useEffect, RefObject } from "react"
import * as React from "react"
import branding from "../../branding/branding"
import { RouteComponentProps, useHistory } from "react-router-dom"
import styled from "styled-components"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import LobbyPageContent from "./LobbyPageContent"
import MyFairPageContent from "./MyFairPageContent"
import PrivacyPolicyModal from "../../ui/modals/PrivacyPolicyModal"
import GuestModal from "../../ui/modals/GuestModal"
import SayHelloModal from "../../ui/modals/SayHelloModal"
import { CalendarEntry } from "../../backendServices/GraphQLServices"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../../ui/modals/CalendarEntryModal"
import CrsTabs, { getUserDefaultViewMode, ViewMode } from "../../ui/CrsTabs"
import { ActionType } from "../entitiesActions/actionsUtils"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useRef } from "react"
import { DesktopVersionContainer, device } from "../../utils/Device"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { useAppState } from "../../globalStates/AppState"
import { IconArrowRightCarousel, IconArrowLeftCarousel } from "../../ui/Icons"
import { HistoryState } from "../program/ProgramPageContent"
import { programPageRoute } from "../../navigationArea/RoutePaths"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../../globalStates/TileViewConfig"

export const TileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 280px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    /* grid-gap: 20px; */
    padding-bottom: 1.25rem;
`

export const MobileTileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 95px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    /* grid-gap: 20px; */
    padding-bottom: 1.25rem;
`

/*********************************************************************************************
 * FAIR INFO
 **********************************************************************************************/

const FairInfoContainerContent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const HeaderTitleRoot = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    vertical-align: center;
    justify-content: center;
    flex: 1;
`

const HeaderTitleText = styled.div`
    color: ${branding.secondaryTextColor ?? "#fff"};
    font-size: ${branding.receptionPage.headerTitleInitialFontSize ?? "100px"};
    font-family: ${branding.receptionPage.headerTitleFontFamily};
    text-align: center;
    font-style: normal;
    text-transform: ${branding.receptionPage.headerTitleTextTransform};
    font-weight: ${branding.receptionPage.headerTitleFontWeight};
    letter-spacing: ${branding.receptionPage.headerTitleLetterSpacing};
    z-index: 2;
    margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTop ?? "40px"};
    @media only screen and (max-width: 2200px) {
        font-size: ${5 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }
    @media only screen and (max-width: 1800px) {
        font-size: ${4.38 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }

    @media only screen and (max-width: 1500px) {
        font-size: ${3.91 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }

    @media only screen and (max-width: 1200px) {
        font-size: ${3.6 - (branding.receptionPage.headerTitleFontSizeIndex ?? 0) + "vw"};
        margin-top: ${branding.receptionPage.lobbyHeaderTitleMarginTopForSmallerScreen ?? "0px"};
    }
`

const HeaderFooterText = styled.div<{ title: string; description: string }>`
    font-size: ${(props) =>
        props.title.length > props.description?.length
            ? 2.5 - branding.receptionPage.headerFooterFontSizeIndex + "vw"
            : `${
                  branding.receptionPage.receptionPageHeaderFontSize ? branding.receptionPage.receptionPageHeaderFontSize : "22px"
              }`};
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: ${branding.receptionPage.receptionPageHeaderFontWeight};
    z-index: 2;
    color: ${branding.secondaryTextColor ?? "#fff"};
`

const MyFairPageHeader = styled.div<{ title: string; description: string }>`
    font-size: ${(props) => (props.title.length > props.description?.length ? "2.5vw" : "22px")};
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    color: ${branding.secondaryTextColor ?? "#fff"};
    a {
        color: ${branding.secondaryTextColor ?? "#fff"};
        border-bottom: 1px solid ${branding.secondaryTextColor ?? "#fff"};
    }
    z-index: 2;
    margin-top: ${branding.receptionPage.myFairHeaderSubtitleMarginTop ?? "5px"};
`

const CarouselInfoContainer = styled.div<{ containerBackground: string; isLobbyHeader: boolean }>`
    position: relative;
    color: white;
    margin: 0 -15px;
    /* height: 550px; */
    padding-top: 12.5%;
    background: ${(props) =>
            props.isLobbyHeader
                ? branding.receptionPage.lobbyHeaderLinearGradient1
                : branding.receptionPage.lobbyHeaderLinearGradient2},
        ${(props) => props.containerBackground};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    display: flex;
    flex-direction: column;

    .networkingCollapsed {
        border-bottom: 0;
        padding-left: 8px;
        padding-right: 10px;
        a {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .active {
        border-bottom: 0;
        padding-left: 8px;
        padding-right: 10px;
        a {
            padding-left: 0;
            padding-right: 0;
        }
    }
`

const MainCarouselDiv = styled.div`
    .slick-next,
    .slick-prev {
        width: 25px;
        height: 25px;
    }
    .slick-prev {
        left: 20px !important;
        z-index: 1;
    }
    .slick-next {
        right: 20px !important;
        z-index: 1;
    }
    .slick-dots {
        bottom: 20px !important;
    }
    .slick-dots li.slick-active button:before {
        color: #fff !important;
    }
    .slick-prev:before,
    .slick-next:before {
        font-size: 25px;
        opacity: 1;
    }

    .slick-dots li button:before {
        color: #fff !important;
        font-size: 10px;
        cursor: pointer;
    }
`
const IconWrapper = styled.div`
    &::before {
        content: none;
    }
`

interface CarouselViewProps {
    eventKey: string
    setEventKey: (value: string) => void
    onRouteChange: (route: string) => void
    fairInfoRootRef?: React.RefObject<HTMLDivElement>
}

const CarouselView: React.FunctionComponent<CarouselViewProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const history = useHistory()

    const scheduleLink = (
        <div
            style={{ textDecoration: "underline", cursor: "pointer", display: "inline-block" }}
            onClick={() => {
                history.push(programPageRoute)
            }}
        >
            {strings.receptionPage.receptionPageMyHeaderDescriptionSecondPart}
        </div>
    )

    function getTitle(): string {
        let title: string = ""
        switch (props.eventKey) {
            case "LOBBY": {
                title = strings.receptionPage.receptionPageLobbyHeaderTitle
                break
            }
            case "MY_FAIR": {
                title = strings.receptionPage.receptionPageMyHeaderTitle
                break
            }
        }
        return title
    }

    function getDescription(): string {
        let description: string = ""
        switch (props.eventKey) {
            case "LOBBY": {
                description = strings.receptionPage.receptionPageLobbyHeaderDescription
                break
            }
            case "MY_FAIR": {
                description = branding.receptionPage.receptionPageMyFairHeaderDesc
                break
            }
        }
        return description
    }

    const title = getTitle()
    const description = getDescription()

    const locations = calcBreadcrumbLocations(strings)
    let breadcrumb = [{ to: locations[0], name: strings.sideIconBar.lobbyMenuText }]
    if (props.eventKey === strings.receptionPage.receptionPageMyHeaderTitle) {
        breadcrumb.push({ to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle })
    }

    const lobbyHeaderPool: string[] = branding.receptionPage.lobbyHeaderPool
    const lobbyHeaderPoolNumber: number[] = branding.receptionPage.lobbyHeaderPoolNumber

    const myPageHeaderPool: string[] = branding.receptionPage.myFairPageHeaderPool
    const myPageHeaderPoolNumber: number[] = branding.receptionPage.myFairPageHeaderPoolNumber

    const isLobbyHeader: boolean = props.eventKey === "LOBBY"

    const renderLobbySlides = () =>
        lobbyHeaderPoolNumber.map((num, index) => (
            <CarouselInfoContainer
                key={index}
                containerBackground={"url(" + lobbyHeaderPool[num] + ")"}
                isLobbyHeader={isLobbyHeader}
            >
                <FairInfoContainerContent>
                    {
                        <HeaderTitleRoot>
                            <HeaderTitleText>{title}</HeaderTitleText>
                            <HeaderFooterText title={title} description={description}>
                                {description}
                            </HeaderFooterText>
                        </HeaderTitleRoot>
                    }
                </FairInfoContainerContent>
            </CarouselInfoContainer>
        ))

    const renderMyFairPageSlides = () =>
        myPageHeaderPoolNumber.map((num, index) => (
            <CarouselInfoContainer key={index} containerBackground={"url(" + myPageHeaderPool[num] + ")"} isLobbyHeader={false}>
                <FairInfoContainerContent>
                    {
                        <HeaderTitleRoot>
                            <HeaderTitleText>{title}</HeaderTitleText>
                            <MyFairPageHeader title={title} description={description}>
                                {strings.receptionPage.receptionPageMyHeaderDescriptionFirstPart} {scheduleLink}{" "}
                                {strings.receptionPage.receptionPageMyHeaderDescriptionThirdPart}
                            </MyFairPageHeader>
                        </HeaderTitleRoot>
                    }
                </FairInfoContainerContent>
            </CarouselInfoContainer>
        ))

    return (
        <MainCarouselDiv className="App">
            <Slider
                dots={true}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={lobbyHeaderPoolNumber.length > 1 || myPageHeaderPoolNumber.length > 1 ? true : false}
                autoplaySpeed={
                    props.eventKey === "LOBBY"
                        ? branding.receptionPage.autoPlayValueLobbyPool
                        : branding.receptionPage.autoPlayValueMyFairPool
                }
                nextArrow={<IconWrapper>{IconArrowRightCarousel({ fill: "#fff" })}</IconWrapper>}
                prevArrow={<IconWrapper>{IconArrowLeftCarousel({ fill: "#fff" })}</IconWrapper>}
            >
                {props.eventKey === "LOBBY" ? renderLobbySlides() : renderMyFairPageSlides()}
            </Slider>
        </MainCarouselDiv>
    )
}

/*********************************************************************************************
 * HEADER ROW
 **********************************************************************************************/

const HeaderRow = styled.div<{ showShadow?: boolean }>`
    position: sticky;
    top: 0;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    height: 115px;
    color: ${branding.mainInfoColor};
    background-color: #fff;
    z-index: 1000;
    padding-bottom: 25px;
    box-sizing: content-box;
    flex-direction: column;
    &::before {
        transition: box-shadow 0.3s ease-in;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 140px;
        // box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    }
`
interface HeaderRowProps {
    eventKey: string
    onEventKeySelect: React.Dispatch<React.SetStateAction<string>>
    showShadow?: boolean
    breadcrumb?: any
    ref?: any
    viewMode: ViewMode
    setViewMode: (value: ViewMode) => void
}

const HeaderRowContent: React.FunctionComponent<HeaderRowProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const itemsOrder: any = branding.receptionPage.crsTabsItemsOrder
    //eslint-disable-next-line

    const history = useHistory()

    enum viewType {
        LOBBY = "LOBBY",
        MY_FAIR = "MY_FAIR"
    }

    let items: any = []

    itemsOrder.forEach((item: any) => {
        let newItem
        switch (item) {
            case viewType.LOBBY:
                newItem = { label: strings.receptionPage.eventKeyLobby, value: viewType.LOBBY }
                break
            case viewType.MY_FAIR:
                newItem = { label: strings.receptionPage.eventKeyMyPage, value: viewType.MY_FAIR }
                break
        }
        items.push(newItem)
    })

    return (
        <HeaderRow showShadow={props.showShadow}>
            <Breadcrumb breadcrumb={props.breadcrumb} />
            <CrsTabs
                tabItems={items}
                activeItem={items.length === 1 ? items[0].value : props.eventKey}
                onTabItemClicked={props.onEventKeySelect}
                viewMode={props.viewMode}
                onViewModeChange={(viewMode) => {
                    props.setViewMode(viewMode)
                    history.replace({
                        state: { ...(history.location.state as HistoryState), viewMode: viewMode }
                    })
                }}
            />
        </HeaderRow>
    )
}

/*********************************************************************************************
 * RECEPTION PAGE CONTENT
 **********************************************************************************************/

const ReceptionPageRoot = styled.div<{ guestBannerHeight: number }>`
    height: 100%; //calc(100% - ${(props) => props.guestBannerHeight + 50 + "px"});
`

const MainContent = styled.div<{ height?: number; carouselHeight: number; headerRowHeight: number }>`
    height: 100%;
    width: 100%;
    background-color: white;

    & .ScrollbarsCustom-TrackY {
        z-index: 10001;
    }

    & .ScrollbarsCustom-Track {
        top: ${(props) => props.carouselHeight + props.headerRowHeight + 160 + "px"} !important; // fix this not to be hard coded
        height: ${(props) =>
            "calc(100% - " +
            props.carouselHeight +
            props.headerRowHeight +
            180 +
            "px)"} !important; // fix this not to be hard coded
    }
`

const MainContentRoot = styled.div`
    padding-bottom: 44px;

    &.MY_SCHEDULE {
        padding-bottom: 0px;
    }
`

const ToggledContent = styled.div<{ displayed: string }>`
    display: ${(props) => props.displayed};
    flex-flow: column;

    &.MY_SCHEDULE {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
        /*overflow: hidden;

        @media ${device.mobile} {
            overflow: auto;
        }*/

        @media ${device.tablet} {
            overflow: hidden;
        }
    }
    &.MY_FAIR {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }

    &.LOBBY {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }
`

interface ReceptionPageProps extends RouteComponentProps {
    receptionRoute: string
    trigger: number
    changeRoute: (route: string) => void
    reloadScheduleList: boolean
    setReloadScheduleList: (value: boolean) => void
}

const ReceptionPageContent: React.FunctionComponent<ReceptionPageProps> = (props) => {
    let userState = useLoggedInState()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const [eventKey, setEventKey] = useState<string>(props.receptionRoute ? props.receptionRoute : "LOBBY")
    const [action, setAction] = useState<ActionType | undefined>()
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState<boolean>(false)
    const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false)
    const [showRequestOrganizationModal, setShowRequestOrganizationModal] = useState(false)
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)
    let [target, setTarget] = useState<SimpleOrganization | null>(null)
    const [selectedMeeting, setSelectedMeeting] = useState<CalendarEntry>()
    const [calendarEntryModalViewMode, setCalendarEntryModalViewMode] = useState(CalendarEntryModalViewMode.CREATE)
    const [refreshKey, setRefreshKey] = useState(0)
    const fairInfoRootRef = useRef<HTMLDivElement>(null)
    const [showShadow, setShowShadow] = useState(false)
    // eslint-disable-next-line
    const [breadcrumbRef, setBreadcrumbRef] = useState<RefObject<HTMLDivElement>>()
    const { isMobile } = useWindowDimensions()
    const carouselRef = useRef<HTMLDivElement>(null)
    const headerRowRef = useRef<HTMLDivElement>(null)
    const appState = useAppState()
    const lang = useLanguageState().getLanguage()
    const history = useHistory()
    const windowSize = useWindowDimensions()

    const [viewMode, setViewMode] = useState<any>((history.location.state as HistoryState)?.viewMode ?? ViewMode.TILES)

    useEffect(() => {
        if (getUserDefaultViewMode(appState) !== null) {
            setViewMode(getUserDefaultViewMode(appState))
        }
    }, [appState])

    const tileViewMinReqWidth = getTileViewMinReqWidth(PagesUsingTileView.SCHEDULE)

    useEffect(() => {
        setViewMode(
            windowSize.width > tileViewMinReqWidth && windowSize.height >= 800
                ? (history.location.state as HistoryState)?.viewMode ?? ViewMode.TILES
                : ViewMode.LIST
        )
        // eslint-disable-next-line
    }, [windowSize, appState.isNetworkingOpen()])

    const policyCallback = (accepted: boolean) => {
        switch (action) {
            case "MEETING":
                setShowRequestMeetingModal(true)
                break
            case "INTEREST":
                setShowRequestOrganizationModal(true)
                break
        }
        setAction(undefined)
        setShowPrivacyPolicyModal(false)
    }

    const reload = props.location.state

    const strings = useLanguageState().getStrings()

    const locations = calcBreadcrumbLocations(strings)
    let breadcrumb = [{ to: locations[0], name: strings.sideIconBar.lobbyMenuText }]
    if (eventKey === "MY_FAIR") {
        breadcrumb.push({ to: "/", name: strings.receptionPage.eventKeyMyPage })
    }

    function setMobileBreadcrumb() {
        if (eventKey === "LOBBY") {
            appState.setCurrentMobileBreadcrumb(strings.sideIconBar.lobbyMenuText)
        } else if (eventKey === "MY_FAIR") {
            appState.setCurrentMobileBreadcrumb(strings.receptionPage.eventKeyMyPage)
        }
    }

    useEffect(() => {
        if (reload) {
            setEventKey("LOBBY")
            props.changeRoute("LOBBY")
            history.replace("", !reload)
        } else {
            props.changeRoute(eventKey)
        }

        setMobileBreadcrumb()

        //eslint-disable-next-line
    }, [eventKey])

    useEffect(() => {
        setMobileBreadcrumb()
        // eslint-disable-next-line
    }, [lang])

    // todo: remove this when Privacy-, SayHello-, and CalendarEntryModal are refactored in GUIDE-3310
    useEffect(() => {
        setTarget(null)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEventKey(props.receptionRoute ? props.receptionRoute : "LOBBY")
    }, [props.trigger, props.receptionRoute])

    const heightToAdjust =
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0)

    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0

    const handleScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setShowShadow(scrollValues.scrollTop > 0)
        }

        if (scrollValues && fairInfoRootRef.current && breadcrumbRef?.current) {
            if (scrollValues.scrollTop > fairInfoRootRef.current?.clientHeight + breadcrumbRef?.current.clientHeight) {
                if (!showShadow) setShowShadow(true)
            } else {
                if (showShadow) setShowShadow(false)
            }
        }
    }

    return (
        <ReceptionPageRoot guestBannerHeight={guestBannerHeight}>
            <DesktopVersionContainer>
                <GuestUserBanner setRef={setGuestUserBannerRef} />
                <TopBar setRef={setTopBarRef} />
            </DesktopVersionContainer>

            <MainContent
                className="scroll"
                height={heightToAdjust}
                carouselHeight={carouselRef.current?.clientHeight || 0}
                headerRowHeight={headerRowRef.current?.clientHeight || 0}
            >
                <ContentScrollContainer handleScroll={handleScroll} adjustForHeaderWith={heightToAdjust + "px"}>
                    <MainContentRoot style={{ paddingBottom: isMobile ? "130px" : "44px" }} className={eventKey}>
                        {showPrivacyPolicyModal && target && (
                            <PrivacyPolicyModal
                                callback={policyCallback}
                                organizationId={target!.id}
                                consentText={target!.consentText}
                                consentTitle={target!.consentTitle}
                            />
                        )}
                        {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
                        {showRequestOrganizationModal && target && (
                            <SayHelloModal
                                organizationModal={true}
                                targetId={target?.id!}
                                handleClose={() => setShowRequestOrganizationModal(false)}
                            ></SayHelloModal>
                        )}
                        {showRequestMeetingModal && target && (
                            <CalendarEntryModal
                                viewMode={calendarEntryModalViewMode}
                                calendarEntry={
                                    calendarEntryModalViewMode !== CalendarEntryModalViewMode.CREATE ? selectedMeeting : undefined
                                }
                                organizationId={target?.id}
                                sotUser={[]}
                                close={() => setShowRequestMeetingModal(false)}
                                onUpdated={() => setRefreshKey(1)}
                            />
                        )}

                        {isMobile ? (
                            <div />
                        ) : (
                            <>
                                {/* <FairInfo fairInfoRootRef={fairInfoRootRef} eventKey={eventKey} onRouteChange={props.changeRoute} /> */}
                                {
                                    <div ref={carouselRef}>
                                        <CarouselView
                                            fairInfoRootRef={fairInfoRootRef}
                                            eventKey={eventKey}
                                            setEventKey={setEventKey}
                                            onRouteChange={props.changeRoute}
                                        ></CarouselView>
                                    </div>
                                }

                                <HeaderRowContent
                                    ref={headerRowRef}
                                    breadcrumb={breadcrumb}
                                    showShadow={showShadow}
                                    eventKey={eventKey}
                                    onEventKeySelect={setEventKey}
                                    viewMode={viewMode}
                                    setViewMode={setViewMode}
                                />
                            </>
                        )}
                        <ToggledContent
                            displayed={"flex"}
                            className={eventKey + " " + (viewMode === ViewMode.LIST ? "list" : "tiles")}
                        >
                            {eventKey === "LOBBY" && (
                                <LobbyPageContent
                                    eventKey={eventKey}
                                    refreshKey={refreshKey}
                                    onSetShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
                                    onSetShowGuestModal={setShowGuestModal}
                                    profileId={userState.user()?.profileId!}
                                    isMobile={isMobile}
                                />
                            )}

                            {eventKey === "MY_FAIR" && (
                                <MyFairPageContent
                                    onViewModeSet={setCalendarEntryModalViewMode}
                                    eventKey={eventKey}
                                    setEventKey={setEventKey}
                                    guestBannerHeight={guestUserBannerRef?.current?.clientHeight || 0}
                                    onSetShowGuestModal={setShowGuestModal}
                                    onSetShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
                                    onSelectedMeetingSet={setSelectedMeeting}
                                    isMobile={isMobile}
                                />
                            )}
                        </ToggledContent>
                    </MainContentRoot>
                </ContentScrollContainer>
            </MainContent>
        </ReceptionPageRoot>
    )
}

export default ReceptionPageContent
